import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import { Cell, Field, CellGroup, Swipe, SwipeItem, ActionSheet, Step, Steps, Dialog, Overlay, CountDown, Popup } from 'vant';
import 'vant/lib/index.css';
import router from './router'
import less from 'less'
import './assets/icons'
import uweb from 'vue-uweb'
// import Vconsole from 'vconsole';
// new Vconsole()
import Function from './utils/function' // 引入
// 使用免费开源版本
import { VueJsonp } from 'vue-jsonp'    // 网上很多博客引用不加{}，会报错



Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Vant);
Vue.use(less)
Vue.use(Cell);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ActionSheet);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Dialog);
Vue.use(CountDown);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(Function) // 添加
// 导入插件(其他插件类似，插件清单访问：http://mars3d.cn/dev/guide/start/install.html)
// echarts插件
// import 'mars3d-echarts'

// 为了方便使用,绑定到原型链，在其他vue文件，直接 this.mars3d 来使用
Vue.use(VueJsonp)
import baiduMap from "vue-baidu-map";
Vue.use(baiduMap, { ak: '7u9QnfpHDTYtMcSdEFjccRp32Vo5wNEC' })

let uwebOptions = {
  siteId: 1280430682,
  debug: false,
  autoPageview: false,
  src: ''
}
Vue.use(uweb, uwebOptions)
// Vue.prototype.$uweb = uweb

//取消点击缩放
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  });
  var lastTouchEnd = 0;
  document.documentElement.addEventListener('touchend', function (event) {
    var now = Date.now();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  });
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
