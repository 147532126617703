<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Home from "./components/Home"
export default {
  name: 'App',
  components: {
    // Home
  }
}
</script>

<style>
*{
  //margin: 0;
  //padding: 0;
  //text-decoration: none;
  //color: #333;
  //overflow-x: hidden;
}
html{
  user-select:none;
  font-family: "PingFang SC";
}
</style>

<!--<style lang="less">-->
<!--#app {-->
<!--  font-family: "Avenir", Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--  -webkit-tap-highlight-color: transparent;-->
<!--  -webkit-overflow-scrolling: touch;-->
<!--}-->
<!--img {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--}-->
<!--@media screen and (orientation: portrait) {-->
<!--  body {-->
<!--    padding-top: 0;-->
<!--    padding-top: constant(safe-area-inset-top);-->
<!--  }-->
<!--}-->
<!--@media screen and (orientation: landscape) {-->
<!--  body {-->
<!--    padding-left: 0;-->
<!--    padding-right: 0;-->
<!--    padding-bottom: 0;-->

<!--    padding-left: constant(safe-area-inset-left);-->
<!--    padding-right: constant(safe-area-inset-right);-->
<!--    padding-bottom: constant(safe-area-inset-bottom);-->

<!--    padding-left: env(safe-area-inset-left);-->
<!--    padding-right: env(safe-area-inset-right);-->
<!--    padding-bottom: env(safe-area-inset-bottom);-->
<!--  }-->
<!--}-->
<!--</style>-->
