<template>
  <div class="message">
    <Navbar></Navbar>
    <h1>
      招聘信息
      <img src="@a/images/icon_yumaobi@3x.png" alt />
    </h1>
    <img class="imagess" src="@a/images/zp_nr@3x.png" alt />
    <div id="title">
      <div class="xinxi">
        <div class="main">
          <div class="main-top">
            <ul>
              <li>全部</li>
              <!-- <li>产品</li>
              <li>技术</li>
              <li>运营</li>-->
            </ul>
          </div>
          <div class="main-bottom">
            <div class="bottom-text">
              <div class="boxbox">
                <h6>短视频编导/导演/编剧</h6>
                <span>10k-15k</span>
                <div class="aaa">3-5年 / 本科 / 全职</div>
                <a
                  href="https://www.zhipin.com/job_detail/6b967e093e91df2b1nV93di-F1VS.html?ka=search_list_jname_3_blank&lid=1f5TZcg1ybF.search.3&securityId=QqT2af2GBcVyZGr2aQyrYeyLNx0RuU8SmshamstFmygtjyBedjw9bc2ymFLvtbjNakn-V2l7skGZnn0uwcs5AquAdUxb"
                  >查看详情</a
                >
              </div>
              <div class="boxbox">
                <h6>PHP后端开发工程师</h6>
                <span>15k-30k.13薪</span>
                <div class="aaa">5-10年 / 本科 / 全职</div>
                <a
                  href="https://www.zhipin.com/job_detail/9de25b6694b20d2c0Hx73dq9E1M~.html?ka=search_list_jname_2_blank&lid=1f5TZcg1ybF.search.2&securityId=6zO1eXV0JKRWb1OhXDW9YfJmAcKCBr1fiNBZp89B5ZxzcAafNcgsPpWkl2OLGyEqc54-Liz5sLjwGZUVdRFX7W7H49s%7E"
                  >查看详情</a
                >
              </div>
              <div class="boxbox">
                <h6>前端开发工程师</h6>
                <span>10k-15k.13薪</span>
                <div class="aaa">1-3年 / 本科 / 全职</div>
                <a
                  href="https://www.zhipin.com/job_detail/643595dc1d759f490Hx73t67EVE~.html?ka=search_list_jname_1_blank&lid=1f5TZcg1ybF.search.1&securityId=LPBEP60rBvEmuXcrulGkfeZgc3y3m5ToCrmVkTOXBpP0rwbdGZwl5-KGdE5HpYp6HGS9O3KZzrULMdBiW3AU4ZvN7D4%7E"
                  >查看详情</a
                >
              </div>
              <!-- <div class="boxbox">
                <h6>广告优化师</h6>
                <span>8k-13k.13薪</span>
                <div class="aaa">3-5年 / 大专 / 全职</div>
                <a href="https://www.zhipin.com/shenzhen/">查看详情</a>
              </div>-->
              <div class="boxbox">
                <h6>健康营养师</h6>
                <span>8k-13k.13薪</span>
                <div class="aaa">经验不限 / 学历不限</div>
                <a
                  href="https://www.zhipin.com/job_detail/85d4794bcabe0d021nV52Nu_EltR.html?ka=search_list_jname_4_blank&lid=1f5TZcg1ybF.search.4&securityId=OF0xTPRH64QZwOkcdv7o-FtqwTSdrVhGsc2SmgUqmYlH6pauGFhsvzeRd-0urkffM1cGQ606swAMgdN2DdOTYvwOZgg8"
                  >查看详情</a
                >
              </div>
              <!-- <div class="boxbox">
                <h6>销售专员</h6>
                <span>6k-11k</span>
                <div class="aaa">经验不限 / 学历不限</div>
                <a href="https://www.zhipin.com/shenzhen/">查看详情</a>
              </div>-->
              <!-- <div class="boxbox">
                <h6>短视频策划运营</h6>
                <span>10k-15k</span>
                <div class="aaa">1~3年 / 大专</div>
                <a href="https://www.zhipin.com/shenzhen/">查看详情</a>
              </div>-->
            </div>
            <div class="bottom-img">
              <div class="img- box1">
                <img class="img1" src="@a/images/img_1.png" alt />
                <img class="img2" src="@a/images/img_2.png" alt />
              </div>

              <div class="img-box2">
                <img class="img3" src="@a/images/img_3.png" alt />
                <img class="img4" src="@a/images/img_4.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: #333;
}

.message {
  width: 100%;
  background-image: url(../assets/images/zp_bg@3x.png);
  background-repeat: no-repeat;
  background-size: 100% 600px;
  background-position: top left;
  position: relative;
}
.message .imagess {
  position: absolute;
  left: 65%;
  top: 100px;
}
h1 {
  padding-left: 150px;
  font-size: 60px;
  color: #fff;
}
#title {
  width: 80%;
  margin: 0 auto;
  padding-top: 320px;
  margin-bottom: 50px;
}
#title .xinxi {
  width: 100%;
}
#title .school {
  height: 42px;
  line-height: 42px;
  overflow-y: hidden;
}
#title .school span {
  display: inline-block;
  width: 92px;
  height: 42px;
  line-height: 42px;
  text-align: center;
}
#title .school .shehui {
  background-color: red;
  border-radius: 50px;
}
#title .main {
  width: 100%;
  padding-top: 50px;
}
#title .main-top {
  overflow: hidden;
}
#title .main-top li {
  width: 52px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  float: left;
  margin-right: 30px;
  color: #888888;
}
#title .main-bottom {
  width: 100%;
  overflow: hidden;
}
#title .bottom-text {
  overflow: hidden;
  width: 50%;
  float: left;
  border-bottom: 1px solid #ededed;
}
#title .boxbox {
  padding-top: 25px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 25px;
  position: relative;
}
.bottom-text h6 {
  width: 100%;
  font-size: 16px;
  padding-bottom: 25px;
  color: #000000;
}
.bottom-text span {
  width: 20%;
  display: inline-block;
  color: red;
  font-size: 14px;
}
.bottom-text .aaa {
  width: 60%;
  display: inline-block;
  font-size: 14px;
  color: #888888;
}
.bottom-text a {
  width: 20%;
  border: 1px solid #333333;
  border-radius: 50px;
  width: 98px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  float: right;
  position: absolute;
  top: 30px;
  right: 0;
  color: #333333;
}
.bottom-img {
  width: 50%;
  float: right;
  padding-left: 50px;
  box-sizing: border-box;
  padding-top: 50px;
}
.img-box1,
.img-box2 {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.bottom-img .img1 {
  width: 240px;
  height: 240px;
  float: left;
  margin-right: 10px;
}
.bottom-img .img2 {
  width: 120px;
  height: 120px;
  float: left;
}
.bottom-img .img3 {
  width: 120px;
  height: 120px;
  margin-top: 10px;
  float: left;
}
.bottom-img .img4 {
  width: 120px;
  height: 120px;
  margin-left: 130px;
  margin-top: 10px;
  float: left;
}

@media screen and (max-width: 1200px) {
  .bottom-img {
    display: none;
    width: 0;
  }
  #title .bottom-text {
    width: 100%;
  }
  .message {
    width: 100%;
    background-image: url(../assets/images/zp_bg@3x.png);
    background-repeat: no-repeat;
    background-size: 100% 500px;
    background-position: top left;
    position: relative;
  }
  h1 {
    padding-top: 20px;
    padding-left: 30px;
    font-size: 20px;
    color: #fff;
  }
  h1 img {
    width: 15px;
    height: 15px;
    display: inline-block;
  }
  .message .imagess {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 15%;
  }
  #title {
    width: 80%;
    margin: 0 auto;
    padding-top: 250px;
    margin-bottom: 50px;
  }
}

/* 媒体查询 */
@media screen and (max-width: 1200px) {
  .bottom-img {
    display: none;
    width: 0;
  }
  #title .bottom-text {
    width: 100%;
  }
  .message {
    width: 100%;
    background-image: url(../assets/images/zp_bg@3x.png);
    background-repeat: no-repeat;
    background-size: 100% 480px;
    background-position: top left;
    position: relative;
  }
  h1 {
    padding-top: 30px;
    padding-left: 17px;
    font-size: 30px;
    color: #fff;
  }
  h1 img {
    width: 20px;
    height: 20px;
    display: inline-block;
  }
  .message .imagess {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 15%;
  }
  #title {
    width: 80%;
    margin: 0 auto;
    padding-top: 250px;
    margin-bottom: 50px;
  }
  #title .main-top li {
    width: 52px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    float: left;
    margin-right: 8px;
    color: #888888;
  }
}
</style>