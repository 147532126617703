<template>
  <div class="kyushu_1">
    <all-header :version="'v1.0.0'" :projectName="'AR地球实景导航'" :project="'arearth'"
                :developer="'深圳悦北科技有限公司'" color="#00182e"></all-header>
    <div class="kefu" @click="kefu">
      <img src="@a/images/icon_service.png" alt/>
    </div>
    <div class="h-title">
      <div class="img">
        <img src="@/assets/images/home_top_app_icon.png" alt/>
      </div>
      <div class="text">
        <div class="icon-t">九州高清街景</div>
        <div class="icon-b">
          <img src="@/assets/images/home_top_wenben.png" alt/>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="head-l">
        <div class="search">
          <van-field
              @click="textUweb"
              v-model="text"
              placeholder="搜索你想要查看的地方"
          />
          <van-button
              type="primary"
              @click="showActionBtn"
              size="mini"
              class="show-btn"
          >立即查看
          </van-button
          >
        </div>
        <div class="head-r">
          <img src="@/assets/images/home_spaceman_img.png" alt/>
        </div>
      </div>
    </div>
    <div class="earth">
      <img src="@/assets/images/home_img_earth.png" alt/>
      <div class="toast-box">
        <img src="@/assets/images/bk.png" alt/>
        <div class="swipe-box">
          <van-swipe
              class="my-swipe"
              :autoplay="1000"
              indicator-color="white"
              :show-indicators="false"
          >
            <van-swipe-item @click="showAction">
              <img class="img-item" src="@/assets/images/img_1.png" alt/>
            </van-swipe-item>
            <van-swipe-item @click="showAction">
              <img class="img-item" src="@/assets/images/img_2.png" alt/>
            </van-swipe-item>
            <van-swipe-item @click="showAction">
              <img class="img-item" src="@/assets/images/img_3.png" alt/>
            </van-swipe-item>
            <van-swipe-item @click="showAction">
              <img class="img-item" src="@/assets/images/img_4.png" alt/>
            </van-swipe-item>
            <van-swipe-item @click="showAction">
              <img class="img-item" src="@/assets/images/img_5.png" alt/>
            </van-swipe-item>
          </van-swipe>
          <div class="swipe-bottom" @click="showAction">查看高清街景>></div>
        </div>
      </div>
    </div>
    <div class="floor-1">
      <div class="title">街景地图></div>
      <div class="imgs">
        <div class="img-box">
          <img src="@/assets/images/home_img_jxjj.png" alt/>
          <div class="text">
            <div class="t-title">家乡街景</div>
            <div class="t-content">高清街景，支持全国300多个城市查询</div>
          </div>
        </div>
        <div class="stone">
          <img src="@/assets/images/home_img_earth_left.png" alt/>
        </div>
        <div class="img-box">
          <img src="@/assets/images/home_img_wzss.png" alt/>
          <div class="text">
            <div class="t-title">位置搜索</div>
            <div class="t-content">
              支持位置关键词搜索
              <br/>想看的地方更精准
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/home_img_wxdt.png" alt/>
          <div class="text">
            <div class="t-title">卫星地图</div>
            <div class="t-content">带你从太空的视角看地球探索世界的美景</div>
          </div>
        </div>
        <div class="stone stone1">
          <img src="@/assets/images/home_img_earth_right.png" alt/>
        </div>
      </div>
    </div>
    <div class="floor-2">
      <div class="title">VIP会员特权></div>
      <div class="imgs">
        <div class="img-box">
          <img src="@/assets/images/home_img_jj.png" alt/>
          <div class="text">全 国 高 清 街 景</div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/home_img_dh.png" alt/>
          <div class="text">全 景 智 能 导 航</div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/home_img_vr.png" alt/>
          <div class="text">全 国 V R 景 点</div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="actionTag" title=" " :round="false" class="pop">
      <div class="content">
        <div class="close-img" @click="onCancel('phone')">
          <img src="@a/images/icon_guanbi.png" alt/>
        </div>

        <div class="tap">为保障数据不丢失，请先注册手机号</div>
        <div class="step-list">
          <div
              v-for="item in stepList"
              :key="item.id"
              class="step-item"
              :style="item.haveLine ? 'flex:1' : ''"
              :class="item.active ? 'step-item-active' : ''"
          >
            <div class="box">
              <div class="icon">{{ item.icon }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
            <div class="line" v-if="item.haveLine">
              <div class="loading">
                <span
                    class="dot"
                    v-for="itm in dotList"
                    :key="itm.id + item.id"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <van-cell-group inset class="phone-list">
          <van-field
              @click="phoneClick"
              v-model="phone"
              maxlength="11"
              label="手机号码"
              placeholder="请输入手机号码"
          />
          <van-field
              @click="rePhoneClick"
              v-model="rephone"
              maxlength="11"
              label="号码确认"
              placeholder="请再次输入手机号码"
          />
        </van-cell-group>
        <van-button
            type="primary"
            class="phone-btn"
            round
            size="large"
            @click="sendPhone"
        >确定
        </van-button
        >
        <div class="clause">
          <div class="clause-icon" @click="checkTag = !checkTag">
            <svg-icon
                iconClass="checkbox"
                class="checkbox"
                v-if="!checkTag"
            ></svg-icon>
            <svg-icon
                iconClass="vip_alipay_selected"
                class="checkbox"
                v-if="checkTag"
            ></svg-icon>
          </div>
          <div class="clause-text">
            同意
            <span class="clause-link" @click="linkTo('sytk')">《使用条款》</span
            >及
            <span class="clause-link" @click="linkTo('yszc')"
            >《隐私政策》</span
            >
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="payTag" title=" " :round="false" class="pop">
      <div class="pay-content">
        <div class="close-img" @click="onCancel">
          <img src="@a/images/icon_guanbi.png" alt/>
        </div>
        <div class="video-box">
          <img :src="require('@a/images/new_banner.png')" alt/>
        </div>
        <div class="price-list">
          <div
              class="price-item"
              v-for="item in priceList"
              :key="item.id"
              :class="item.active ? 'active' : ''"
              @click="changePrice(item)"
          >
            <div class="item-title">{{ item.title }}</div>
            <div class="item-price">
              <span style="font-size: 20px">￥</span>
              <span>{{ item.member_price }}</span>
            </div>
            <div class="item-everyday">
              <span class="item-eve-mark">￥</span>
              <span class="item-eve-price">{{ item.desc }}</span>
            </div>
          </div>
        </div>
        <van-radio-group v-model="checked" class="ali-or-we">
          <van-cell-group>
            <van-cell
                title="微信"
                clickable
                @click="checked = 1"
                v-if="wePay"
                :class="checked == 1 ? 'select' : ''"
            >
              <template #right-icon>
                <van-radio :name="1">
                  <template #icon="props">
                    <svg-icon
                        :iconClass="
                        props.checked
                          ? 'vip_alipay_selected'
                          : 'vip_wechat_default'
                      "
                        class="wechat"
                    ></svg-icon>
                  </template>
                </van-radio>
              </template>
              <template #icon>
                <div class="svg-box">
                  <svg-icon iconClass="wechat"></svg-icon>
                </div>
              </template>
            </van-cell>
            <van-cell
                title="支付宝"
                clickable
                @click="checked = 2"
                v-if="aliPay"
                :class="checked == 2 ? 'select' : ''"
            >
              <template #right-icon>
                <van-radio :name="2">
                  <template #icon="props">
                    <svg-icon
                        :iconClass="
                        props.checked
                          ? 'vip_alipay_selected'
                          : 'vip_wechat_default'
                      "
                        class="wechat"
                    ></svg-icon>
                  </template>
                </van-radio>
              </template>
              <template #icon>
                <div class="svg-box">
                  <svg-icon iconClass="vip_alipay"></svg-icon>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-button
            type="primary"
            round
            class="pay-btn"
            size="large"
            @click="payNow"
        >立即开通
        </van-button
        >
        <div class="clause">
          <div class="clause-icon" @click="clauseTag = !clauseTag">
            <svg-icon
                iconClass="checkbox"
                class="clause-svg"
                v-if="!clauseTag"
            ></svg-icon>
            <svg-icon
                iconClass="vip_alipay_selected"
                class="clause-svg"
                v-if="clauseTag"
            ></svg-icon>
          </div>
          <div class="clause-text">
            同意
            <span class="clause-link" @click="linkTo('vipxy')"
            >《付费会员服务协议》</span
            >
            <span class="clause-link" @click="linkTo('yszc')"
            >《隐私政策》</span
            >
            <span class="clause-link" @click="linkTo('sytk')"
            >《使用条款》</span
            >
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-dialog
        v-model="stayDialog"
        :before-close="onBeforeClose"
        title="支付确认"
        class="stay-dialog"
        confirmButtonText="好的"
    >
      <div class="img-box">
        <img src="@/assets/images/popup_icon_wenhao.png" alt/>
      </div>

      <div class="text">请确认您是否已支付完成， 点击下方按钮确认</div>
    </van-dialog>
    <van-overlay :show="paySuccess" lock-scroll>
      <div class="pay-success" @click.stop>
        <div class="p-s-title">开通成功</div>
        <div class="p-s-content">
          <div class="p-s-cicon">
            <img src="@a/images/popup_img_yse.png" alt/>
          </div>
          <div class="p-s-ctext">
            恭喜您开通成功，请下载应用后使用绑定的手机号登录，登录后即可体验所有功能！
          </div>
          <div class="p-s-clist">
            <div class="p-s-citem">
              <div class="svg-box">
                <img src="@a/images/popup_icon_user.png" alt/>
              </div>
              <span class="p-s-label">登录账号</span>：
              <span class="p-s-content">{{ textPhone }}</span>
            </div>
            <div class="p-s-citem">
              <div class="svg-box">
                <img src="@a/images/popup_icon_vip.png" alt/>
              </div>
              <span class="p-s-label">VIP会员</span>：
              <span class="p-s-content">登录后自动发放</span>
            </div>
          </div>
          <div class="p-s-clink">
            <span class="link-span van-ellipsis">{{ copylink }}</span>
            <van-button
                type="primary"
                size="mini"
                @click="copy"
                round
                class="copy-btn"
            >复制
            </van-button
            >
          </div>
        </div>
        <div class="p-s-btn">
          <van-button
              type="primary"
              size="large"
              round
              @click.stop="downLoad"
              class="download-btn"
              v-if="!download_tag"
          >立即下载
          </van-button
          >
          <van-button
              loading
              type="primary"
              size="large"
              round
              loading-text="下载中..."
              class="loading-btn"
              v-if="download_tag"
          />
        </div>
        <!-- <div class="p-s-btn">
          <van-button
            type="primary"
            size="large"
            round
            @click.stop="toWxDemo"
            class="download-btn"
            >小程序</van-button
          >
        </div> -->
        <div class="service">
          <div class="service-box" @click="linkTo('kf')">
            <span>有问题？联系在线客服</span>
            <div class="svg-box">
              <img src="@a/images/popup_icon_service.png" alt/>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <div v-html="alipay" ref="alipaywap" style="display: none"></div>
    <van-overlay :show="overlayShow" @click="overlayShow = true">
      <div class="guide-box">
        <img class="img1" src="@a/images/img1.png"/>
        <div>请点击右上角</div>
        <div>
          选择“浏览器”
          <img class="img2" src="@a/images/img2.png"/> 打开
        </div>
        <div>微信/QQ内无法使用</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import allHeader from "@/components/header";
import {order, getOrder, getGoods} from "./index";
import {Toast} from "vant";

export default {
  name: "index",
  components: {
    allHeader,
  },
  data() {
    return {
      text: "",
      actionTag: false,
      payTag: false,
      phone: "",
      rephone: "",
      textPhone: "",
      active: 0,
      stepList: [
        {
          icon: "第一步",
          text: "注册手机号",
          haveLine: true,
          id: 1,
          active: true,
        },
        {
          icon: "第二步",
          text: "下载应用",
          haveLine: true,
          id: 2,
          active: false,
        },
        {
          icon: "第三步",
          text: "查看街景",
          haveLine: false,
          id: 3,
          active: false,
        },
      ],
      dotList: [{id: "a"}, {id: "b"}, {id: "c"}],
      payNum: 0, //支付金额
      checked: 1, //支付方式
      wePay: true, //支持微信支付
      aliPay: true, //支持支付宝支付
      stayDialog: false, //支付确认
      paySuccess: false, //支付成功
      checkTag: true, //手机号 选择
      clauseTag: true, //支付方式协议tag
      alipay: "",
      copylink:
          "https://jiuzhou.yuebei99.com/static/share.html?166d78d04c74c3c22e33d74eaa8c0cc7",
      download_tag: false,
      overlayShow: false,
      priceList: [],
    };
  },
  watch: {
    stayDialog(value) {
      if (value) {
        this.$uweb.trackEvent("eject_payment_inspect", "版1弹出支付确认弹窗", [
          `${this.$route.path}`,
        ]);
      }
    },
  },
  mounted() {
  },
  created() {
    this.$uweb.trackEvent("enter_the_home_page", "版1加载", [
      `${this.$route.path}`,
    ]);
    this.isPay();
    this.getGoodsDetail();
    this.textPhone = window.localStorage.getItem("userMobil") || "";
    var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //在微信中打开
      return (this.overlayShow = true);
    }
    if (
        this.$route.query.load == 1 &&
        ua.indexOf("applewebkit") > -1 &&
        ua.indexOf("mobile") > -1 &&
        ua.indexOf("safari") > -1 &&
        ua.indexOf("linux") === -1 &&
        ua.indexOf("android") === -1 &&
        ua.indexOf("chrome") === -1 &&
        ua.indexOf("ios") === -1 &&
        ua.indexOf("browser") === -1
    ) {
      this.$router.push("download");
    }
  },
  methods: {
    //获取价格
    async getGoodsDetail() {
      let res = await getGoods();
      let list = res.data;
      list.forEach((item) => {
        item.active = false;
        if (item.is_default == 1) {
          item.active = true;
        }
      });
      this.priceList = res.data;
    },
    // 输入框输入埋点
    textUweb() {
      this.$uweb.trackEvent("click_enter_address_box", "版1输入框点击", [
        `${this.$route.path}`,
      ]);
    },
    // 是否购买
    isPay() {
      let id = window.localStorage.getItem("order_id");
      if (id) {
        this.stayDialog = true;
      }
      let paied = window.localStorage.getItem("paied");
      if (paied) {
        this.paySuccess = true;
      }
    },
    // 点击搜索
    showActionBtn() {
      let phone = window.localStorage.getItem("userMobil");
      this.$uweb.trackEvent("eject_the_bind_phone", "版1弹出绑定手机号码弹窗", [
        `${this.$route.path}`,
      ]);
      if (phone) {
        this.payTag = true;
      } else {
        this.actionTag = true;
      }
    },
    // 展示绑定手机号
    showAction() {
      let phone = window.localStorage.getItem("userMobil");
      this.$uweb.trackEvent("click_view_street_look", "版1点击查看高清街景", [
        `${this.$route.path}`,
      ]);
      this.$uweb.trackEvent("eject_the_bind_phone", "版1弹出绑定手机号码弹窗", [
        `${this.$route.path}`,
      ]);
      if (phone) {
        this.payTag = true;
      } else {
        this.actionTag = true;
      }
    },
    // 点击手机号输入框
    phoneClick() {
      this.$uweb.trackEvent("click_input_box_phone", "版1点击手机号输入框", [
        `${this.$route.path}`,
      ]);
    },
    // 点击验证手机号输入框
    rePhoneClick() {
      this.$uweb.trackEvent(
          "click_input_confim_phpne",
          "版1点击验证手机号输入框",
          [`${this.$route.path}`]
      );
    },
    // 注册手机号
    sendPhone() {
      if (!this.phone) {
        return Toast("请输入手机号码");
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        return Toast("手机号码输入不正确，请重试");
      }
      if (!this.rephone) {
        return Toast("请再次输入手机号");
      }
      if (this.phone != this.rephone) {
        return Toast("两次输入不一致");
      }
      if (!this.checkTag) {
        return Toast("请阅读勾选协议条款");
      }
      this.$uweb.trackEvent("click_obtain_yes_button", "版1注册手机号", [
        `${this.$route.path}`,
      ]);
      this.$uweb.trackEvent("eject_payment_pop_up", "版1弹出付费弹窗", [
        `${this.$route.path}`,
      ]);
      window.localStorage.setItem("userMobil", this.phone);
      this.textPhone = window.localStorage.getItem("userMobil") || "";
      this.actionTag = false;
      this.payTag = true;
    },
    // 切换选择价格
    changePrice(item) {
      this.priceList.forEach((eve) => {
        eve.active = false;
        if (item.member_price === eve.member_price) {
          eve.active = true;
        }
      });
    },
    // 支付
    async payNow() {
      const that = this;
      if (!that.clauseTag) {
        return Toast("请阅读勾选协议");
      }
      this.$uweb.trackEvent("click_kaitong_now", "版1点击立即开通", [
        `${that.$route.path}`,
      ]);

      let params = {
        pay_type: `${that.checked}`,
        mobile: window.localStorage.getItem("userMobil"),
        link: window.location.href,
        encoding: window.localStorage.getItem("userMobil"),
        pay_class: "h5",
      };
      console.log(params)
      that.priceList.forEach((eve) => {
        if (eve.active) {
          params.goods_id = eve.id;
          params.order_price = eve.member_price
          params.goods_day = eve.day
          if (eve.id == 2) {
            this.$uweb.trackEvent(
                "click_price_kaitong_now_1",
                "v1点击永久会员后点击立即开通",
                [`${this.$route.path}`]
            );
          } else {
            this.$uweb.trackEvent(
                "click_price_kaitong_now_2",
                "v1点击三个月会员后点击立即开通",
                [`${this.$route.path}`]
            );
          }
        }
      });
      let res = await order(params);
      window.localStorage.setItem("order_id", res.data.order_sn);
      if (this.checked == 1) {
        window.location.href = res.data.h5_url;
      }
      if (this.checked == 2) {
        that.alipay = res.data.body
        const div = document.createElement('div');
        div.innerHTML = res.data.body
        document.body.appendChild(div);
        document.forms[0].submit();
      }
      setTimeout(function () {
        this.payTag = false;
        this.stayDialog = true;
      }, 1000);
    },
    // 链接
    linkTo(str) {
      let link = "";
      switch (str) {
        case "qxxq":
          link = "https://jiuzhou.yuebei99.com/static/txauth.html";
          break;
        case "yszc":
          link = "https://jiuzhou.yuebei99.com/static/privacypolicy.html";
          break;
        case "vipxy":
          link = "https://jiuzhou.yuebei99.com/static/membershi.html";
          break;
        case "sytk":
          link = "https://jiuzhou.yuebei99.com/static/useterms.html";
          break;
        case "kf":
          link =
              "https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=TXv2Bq4nm8";
      }
      window.location.href = link;
    },
    // 客服
    kefu() {
      Toast("在线客服仅服务VIP会员哦");
      this.$uweb.trackEvent("clcick_online_service_1", "版1点击客服", [
        `${this.$route.path}`,
      ]);
    },
    // 订单查询
    async onBeforeClose(action, done) {
      if (action == "confirm") {
        let params = {
          // order_sn: window.localStorage.getItem("order_id"),
          order_sn: "80002023082813510720186727841465",
          pay_type: 1,
        };
        let res = await getOrder(params);
        window.localStorage.setItem("order_id", "");
        if (res.code == 200) {
          this.paySuccess = true;
          window.localStorage.setItem("paied", true);
          this.$copy("166d78d04c74c3c22e33d74eaa8c0cc7");
          this.$uweb.trackEvent(
              "hui_yaun_zhi_fu_success",
              "版1弹出开通成功弹窗",
              [`${this.$route.path}`]
          );
          setTimeout(function () {
            done();
          }, 200);
        } else if (res.code == 400) {
          Toast("订单支付失败");
          this.$copy("166d78d04c74c3c22e33d74eaa8c0cc7");
          done();
        } else {
          setTimeout(function () {
            done();
          }, 1000);
        }
      }
    },
    // 下载app
    downLoad() {
      const that = this;
      this.download_tag = true;
      console.log(this.$route.path)
      this.$uweb.trackEvent("click_the_download_now_1", "版1点击立即下载", [
        `${this.$route.path}`,
      ]);
      setTimeout(function () {
        that.download_tag = false;
      }, 5000);
      this.$copy("166d78d04c74c3c22e33d74eaa8c0cc7");
      this.$downLoad("shop");
    },
    // toWxDemo() {
    //   window.location.href = "weixin://dl/business/?t=G1gcHMUykfm";
    // },
    // 关闭action
    onCancel(str) {
      if (str === "phone") {
        this.$uweb.trackEvent("click_payment_close", "版1点击关闭", [
          `${this.$route.path}`,
        ]);
      }
      this.actionTag = false;
      this.payTag = false;
    },
    // 复制功能
    copy() {
      this.$uweb.trackEvent("click_download_copy", "版1点击复制", [
        `${this.$route.path}`,
      ]);
      this.$copy(this.copylink);
      Toast("复制成功");
    },
  },
};
</script>
<style lang="less">
//#app {
//  font-family: "Avenir", Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//  -webkit-tap-highlight-color: transparent;
//  -webkit-overflow-scrolling: touch;
//}
//img {
//  width: 100%;
//  height: 100%;
//}
//@media screen and (orientation: portrait) {
//  body {
//    padding-top: 0;
//    padding-top: constant(safe-area-inset-top);
//  }
//}
//@media screen and (orientation: landscape) {
//  body {
//    padding-left: 0;
//    padding-right: 0;
//    padding-bottom: 0;
//
//    padding-left: constant(safe-area-inset-left);
//    padding-right: constant(safe-area-inset-right);
//    padding-bottom: constant(safe-area-inset-bottom);
//
//    padding-left: env(safe-area-inset-left);
//    padding-right: env(safe-area-inset-right);
//    padding-bottom: env(safe-area-inset-bottom);
//  }
//}
</style>
<style lang="less" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}
img {
  width: 100%;
  height: 100%;
}
@media screen and (orientation: portrait) {
  body {
    padding-top: 0;
    padding-top: constant(safe-area-inset-top);
  }
}
@media screen and (orientation: landscape) {
  body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);

    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
.kyushu_1 {
  background-image: url("../../assets/images/home_bg.jpg");
  background-size: 100%;

  .kefu {
    width: 39px;
    height: 68px;
    position: fixed;
    right: 20px;
    top: 30%;
    z-index: 3;
  }

  .h-title {
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      margin-right: 10px;
    }

    .text {
      width: 50%;
      text-align: left;

      .icon-t {
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 10px;
      }

      .icon-b {
        width: 216px;
      }
    }
  }

  .head {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-right: 30px;
    align-items: center;
    position: relative;

    .head-l {
      position: relative;

      .search {
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px 0px #5829f1, 0px 2px 4px 0px #5829f1 inset;
        border-radius: 13px;
        overflow: hidden;
        padding: 20px 5px;
        display: flex;
        align-items: center;

        /deep/ .van-cell {
          padding: 4px;
          font-size: 16px;
        }

        /deep/ .van-cell::after {
          display: none;
        }

        /deep/ .van-field__control {
          color: #fff;
        }

        /deep/ .van-field {
          background: transparent;
        }

        .show-btn {
          text-align: right;
          flex-shrink: 0;
          border-radius: 20px;
          padding: 17px 15px;
          background: linear-gradient(180deg, #fa9968 0%, #f6d14a 100%);
          border: unset;
          font-size: 16px;
        }
      }

      .head-r {
        width: 20%;
        position: absolute;
        top: -21%;
        right: -14%;
      }
    }
  }

  .earth {
    margin-top: 20px;
    padding: 0 20px;
    position: relative;

    .toast-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 37%;

      .swipe-box {
        width: 93%;
        height: 93%;
        position: absolute;
        top: 3.5%;
        left: 3.5%;

        .my-swipe {
          color: #fff;
          font-size: 20px;
          text-align: center;
          border-radius: 12%;
          -webkit-transform: rotate(0deg);
          overflow: hidden;

          .img-item {
            display: block;
          }
        }

        .swipe-bottom {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          background: rgba(9, 9, 9, 0.6);
          border-radius: 0px 0px 15px 15px;
          position: absolute;
          bottom: 17%;
          left: 1px;
          right: 1px;
          top: 68%;
          line-height: 15%;
          padding: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        /deep/ .van-swipe-item {
          border-radius: 12%;
          -webkit-transform: rotate(0deg);
          overflow: hidden;
        }
      }
    }
  }

  .floor-1 {
    padding: 20px 10px 0;
    margin-bottom: 60px;
    position: relative;

    .title {
      display: inline-block;
      position: absolute;
      top: -30px;
      right: 0;
      background: #fb5a7e;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 10px 10px 10px 16px;
      font-weight: 500;
      color: #ffffff;
    }

    .imgs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .img-box {
        width: 36%;
        margin-bottom: 27px;
        position: relative;

        .text {
          position: absolute;
          left: 5px;
          right: 5px;
          bottom: 14px;
          top: 17px;
          color: #ffffff;

          .t-title {
            margin-bottom: 8px;
          }

          .t-content {
            font-size: 10px;
          }
        }
      }

      .stone {
        width: 19.2%;
        margin-bottom: 27px;
        display: flex;
        align-items: flex-end;

        img {
          height: unset;
        }
      }

      .stone1 {
        transform: translate(-100%, -40%);
      }
    }
  }

  .floor-2 {
    padding: 0 10px 60px;
    position: relative;

    .title {
      display: inline-block;
      position: absolute;
      top: -66px;
      left: 0;
      background: #fb5a7e;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 10px 10px 10px 16px;
      font-weight: 500;
      color: #ffffff;
    }

    .imgs {
      display: flex;
      justify-content: space-between;

      .img-box {
        width: 30%;
        position: relative;

        .text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }

  .pop {
    background: transparent;
    max-height: unset;

    /deep/ .van-action-sheet__header {
      height: 50px;
      background: transparent;

      .van-icon {
        display: none;
      }
    }

    /deep/ .van-action-sheet__content {
      overflow-y: visible;
    }
  }

  .content {
    background: #fff;
    padding: 40px 20px 16px;
    text-align: left;

    .close-img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
      top: 15px;
    }

    .tap {
      background: linear-gradient(180deg, #5521f0 0%, #916ff7 100%);
      border-radius: 20px;
      font-weight: 500;
      color: #ffffff;
      font-size: 14px;
      display: inline-block;
      padding: 6px;
    }

    .step-list {
      display: flex;
      margin-top: 14px;

      .step-item {
        display: flex;
        align-items: center;

        .icon {
          font-size: 14px;
          margin: 0 auto 5px;
          background: #d8d8d8;
          border-radius: 12px;
          padding: 4px 12px;
          text-align: center;
          color: #ffffff;
        }

        .text {
          font-size: 14px;
          color: #090909;
        }

        .line {
          display: flex;
          flex: 1;
          justify-content: space-around;
          align-self: self-start;
          transform: translate(0, 26%);

          .loading {
            .dot {
              width: 9px;
              height: 9px;
              background: #e4e4e4;
              margin: 0 5px;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      .step-item-active {
        .icon {
          background: linear-gradient(180deg, #7569ef 0%, #9992f5 100%);
        }

        .line {
          .loading span {
            background: #9289f4 !important;
            -webkit-animation: loading 1s infinite alternate;
            animation: loading 1s infinite alternate;
          }

          .loading span:nth-of-type(2) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
          }

          .loading span:nth-of-type(3) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
          }

          .loading span:nth-of-type(4) {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
          }

          @-webkit-keyframes loading {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }

          @keyframes loading {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
        }
      }
    }

    .phone-list {
      margin: 10px 0 0 0;

      /deep/ .van-field__control {
        font-weight: 500;
        color: #090909;
      }

      /deep/ .van-cell__title {
        flex: 2;
        flex-shrink: 0;
        min-width: 80px;
        margin-right: 0;
        padding: 10px 8px;
      }

      /deep/ .van-cell__value {
        flex: 8;
        padding: 10px 16px;
        background: #f3f3f3;
        border-radius: 7px;
      }

      /deep/ .van-cell {
        font-size: 16px;
        padding: 0;
        margin-bottom: 15px;
      }
    }

    /deep/ .van-cell::after {
      display: none;
    }

    /deep/ .van-cell-group::after {
      display: none;
    }

    .phone-btn {
      background: linear-gradient(180deg, #7569ef 0%, #9992f5 100%);
      box-shadow: 0px 3px 9px 0px rgba(144, 136, 243, 0.24);
      border: unset;
      margin-top: 20px;
    }

    .clause {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 85px;

      .clause-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .clause-text {
        color: #828e96;
        font-size: 12px;

        .clause-link {
          color: #9289f4;
        }
      }
    }
  }

  .pay-content {
    background: #fff;

    .close-img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
      top: 17px;
    }

    .video-box {
      position: relative;

      img {
        display: block;
      }
    }

    /deep/ .van-cell__title {
      text-align: left;
    }

    .price-list {
      margin: 20px 0 24px;
      display: flex;
      justify-content: space-around;

      .price-item {
        padding: 10px 0 0;
        background: radial-gradient(#ffffff 60%, #dfdcff 150%);
        border-radius: 16px;
        border: 2px solid #bcc7e4;
        color: #828e96;
        overflow: hidden;
        position: relative;

        .item-title {
          font-size: 18px;
          font-family: PingFangSC;
          padding: 0 12px;
        }

        .item-price {
          padding: 10px 12px 26px;
          font-size: 26px;
        }

        .item-everyday {
          font-size: 14px;
          color: #fff;
          background: #bcc7e4;
          position: absolute;
          bottom: -2px;
          left: -2px;
          right: -2px;
        }
      }

      .active {
        border: 2px solid #9289f4;
        color: #9289f4;

        .item-everyday {
          background: #9289f4;
        }
      }
    }

    .ali-or-we {
      .svg-box {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }

      /deep/ .wechat {
        width: 20px;
        height: 20px;
      }

      /deep/ .van-cell {
        margin: 0 10px 12px;
        border-radius: 500px;
        background: #f3f3f3;
        width: inherit;
      }

      /deep/ .van-cell-group::after {
        border-width: 0;
      }

      .select {
        border: 2px solid #9289f4;
      }
    }

    .pay-btn {
      //width: 80%;
      border: 0;
      background: linear-gradient(180deg, #ffac81 0%, #ffd84b 100%);
    }

    .clause {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 46px;

      .clause-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .clause-text {
        font-size: 12px;

        .clause-link {
          color: #9289f4;
        }
      }
    }
  }

  .stay-dialog {
    /deep/ .van-dialog__header {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f2c2c;
    }

    /deep/ .van-dialog__content {
      .img-box {
        width: 47px;
        height: 47px;
        margin: 15px auto;
      }

      .text {
        width: 60%;
        margin: 0 auto;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2f2c2c;
      }
    }

    /deep/ .van-hairline--top::after {
      border-top-width: 0;
    }

    /deep/ .van-dialog__footer {
      .van-button {
        width: 60%;
        background: #9289f4;
        border-radius: 26px;
        flex: unset;
        margin: 10px auto 18px;
        color: #ffffff;
      }
    }
  }

  .pay-success {
    width: 60%;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;

    .p-s-title {
      font-size: 18px;
      font-weight: 500;
      color: #302e51;
      margin-bottom: 10px;
      text-align: center;
    }

    .p-s-content {
      .p-s-cicon {
        width: 93px;
        height: 83px;
        margin: 0 auto 10px;
      }

      .p-s-ctext {
        text-align: left;
        font-size: 15px;
        font-family: PingFangSC;
        color: #302e51;
        margin-bottom: 10px;
      }

      .p-s-clist {
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        max-width: 220px;

        .p-s-citem {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #302e51;
          margin-bottom: 10px;

          .svg-box {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
          }

          .p-s-label {
            text-align: center;
            display: inline-block;
            width: 69px;
            flex-shrink: 0;
            font-size: 14px;
            word-break: keep-all;
          }

          .p-s-content {
            font-weight: 400;
            color: #939393;
            word-break: keep-all;
          }
        }
      }

      .p-s-clink {
        display: flex;
        width: 90%;
        margin: 5px auto 8px;
        color: #9289f4;
        justify-content: space-around;
        align-items: center;

        .link-span {
          text-align: left;
          margin-right: 10px;
          word-break: break-all;
        }

        .copy-btn {
          background: linear-gradient(180deg, #5521f0 0%, #916ff7 100%);
          border: unset;
          padding: 2px 10px;
          flex-shrink: 0;
          text-align: center;
        }
      }
    }

    .p-s-btn {
      width: 90%;
      margin: 0 auto;

      .download-btn {
        background: linear-gradient(180deg, #ffac81 0%, #ffd84b 100%);
        border: unset;
      }

      .loading-btn {
        background: #bdbdbd;
        border: unset;
      }
    }

    .service {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #9289f4;
      font-size: 12px;
      margin-top: 20px;

      .service-box {
        background: #f3f3f3;
        border-radius: 999px;
        // width: 50%;
        display: flex;
        align-items: center;
        padding: 4px 13px;
      }

      .svg-box {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
}

/deep/ .van-action-sheet__close {
  z-index: 99;
}

.wrapper {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;

  div {
    align-self: flex-end;
    position: relative;
    top: 0.5em;
  }

  .share_icon {
    width: 5em;
    height: 5em;
  }
}

.van-overlay {
  z-index: 1000;
  text-align: right;
  font-size: 14px;

  .guide-box {
    color: white !important;
    margin-right: 10px;

    .img1 {
      width: 112px;
      height: 148px;
    }

    .img2 {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
