<template>
  <div class="kyushu_2">
    <div class="header">
      <div class="text">应用名：九州高清街景 V1.0.7</div>
      <div class="text">
        开发者：深圳悦北科技有限公司
        <span @click="linkTo('qxxq')">权限详情</span>
        <!--        <span @click="linkTo('yszc')">隐私政策</span>-->
      </div>
    </div>
    <div class="kefu" @click="kefu">
      <img src="@a/images/v2-x/icon_kefu.png" alt/>
    </div>
    <div class="logo">
      <div class="logo-box">
        <img src="@a/images/v2-x/home_top_app_icon.png" alt/>
      </div>
      <div class="title-box">
        <img src="@a/images/v2-x/wenzi_1.png" alt/>
      </div>
    </div>
    <div class="input">
      <van-field v-model="text" placeholder="请输入想要查看的地方">
        <template #button>
          <div class="input-btn" @click="showActionBtn()">立即查看</div>
        </template>
      </van-field>
    </div>
    <div class="title">
      <img src="@a/images/v2-x/biaoti_1.png" alt/>
      <div class="title-btn">多项专属特权</div>
    </div>
    <div class="gongge">
      <img src="@a/images/v2-x/4_img.png" alt/>
    </div>
    <div class="title">
      <img src="@a/images/v2-x/biaoti_2.png" alt/>
    </div>
    <div class="gongge">
      <img src="@a/images/v2-x/home_icon_8.png" alt/>
    </div>
    <div class="bottom">
      <img src="@a/images/v2-x/home_Last_img_4.png" alt/>
    </div>
    <van-action-sheet v-model="actionTag" title=" " :round="false" class="pop">
      <div class="content">
        <div class="close-img" @click="onCancel('phone')">
          <img src="@a/images/icon_guanbi.png" alt/>
        </div>

        <div class="tap">为保障数据不丢失，请先注册手机号</div>
        <div class="step-list">
          <div
              v-for="item in stepList"
              :key="item.id"
              class="step-item"
              :style="item.haveLine ? 'flex:1' : ''"
              :class="item.active ? 'step-item-active' : ''"
          >
            <div class="box">
              <div class="icon">{{ item.icon }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
            <div class="line" v-if="item.haveLine">
              <div class="loading">
                <span
                    class="dot"
                    v-for="itm in dotList"
                    :key="itm.id + item.id"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <van-cell-group inset class="phone-list">
          <van-field
              @click="phoneClick"
              v-model="phone"
              maxlength="11"
              label="手机号码"
              placeholder="请输入手机号码"
          />
          <van-field
              @click="rePhoneClick"
              v-model="rephone"
              maxlength="11"
              label="号码确认"
              placeholder="请再次输入手机号码"
          />
        </van-cell-group>
        <van-button
            type="primary"
            class="phone-btn"
            round
            size="large"
            @click="sendPhone"
        >确定
        </van-button
        >
        <div class="clause">
          <div class="clause-icon" @click="checkTag = !checkTag">
            <svg-icon
                iconClass="uncheck"
                class="checkbox"
                v-if="!checkTag"
            ></svg-icon>
            <svg-icon
                iconClass="checked"
                class="checkbox"
                v-if="checkTag"
            ></svg-icon>
          </div>
          <div class="clause-text">
            同意
            <span class="clause-link" @click="linkTo('sytk')">《使用条款》</span
            >及
            <span class="clause-link" @click="linkTo('yszc')"
            >《隐私政策》</span
            >
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="payTag" title=" " :round="false" class="pop">
      <div class="pay-content">
        <div class="close-img" @click="onCancel">
          <img src="@a/images/icon_guanbi.png" alt/>
        </div>
        <div
            class="video-box"
            :style="`width:${video.width}px;height:${video.height}px`"
        >
          <img :src="require('@a/images/video.gif')" alt/>

          <div class="video-mode" @click.stop></div>
        </div>
        <div class="text-tip">
          <img src="@a/images/v2/quanqiuv2.png" alt/>
        </div>
        <div class="pay-num">
          <img src="@a/images/v2/vip_wenbv2.png" class="vip_wenb" alt/>
          <span class="pay-color"> 49.9元</span>
        </div>
        <van-radio-group v-model="checked" class="ali-or-we">
          <van-cell-group>
            <van-cell
                title="微信"
                clickable
                @click="checked = 1"
                v-if="wePay"
                :class="checked == 1 ? 'select' : ''"
            >
              <template #right-icon>
                <van-radio :name="1">
                  <template #icon="props">
                    <svg-icon
                        :iconClass="
                        props.checked ? 'checked' : 'vip_wechat_default'
                      "
                        class="wechat"
                    ></svg-icon>
                  </template>
                </van-radio>
              </template>
              <template #icon>
                <div class="svg-box">
                  <svg-icon iconClass="wechat"></svg-icon>
                </div>
              </template>
            </van-cell>
            <van-cell
                title="支付宝"
                clickable
                @click="checked = 2"
                :class="checked == 2 ? 'select' : ''"
                v-if="aliPay"
            >
              <template #right-icon>
                <van-radio :name="2">
                  <template #icon="props">
                    <svg-icon
                        :iconClass="
                        props.checked ? 'checked' : 'vip_wechat_default'
                      "
                        class="wechat"
                    ></svg-icon>
                  </template>
                </van-radio>
              </template>
              <template #icon>
                <div class="svg-box">
                  <svg-icon iconClass="vip_alipay"></svg-icon>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-button
            type="primary"
            round
            class="pay-btn"
            size="large"
            @click="payNow"
        >立即开通
        </van-button
        >
        <div class="clause">
          <div class="clause-icon" @click="clauseTag = !clauseTag">
            <svg-icon
                iconClass="uncheck"
                class="clause-svg"
                v-if="!clauseTag"
            ></svg-icon>
            <svg-icon
                iconClass="checked"
                class="clause-svg"
                v-if="clauseTag"
            ></svg-icon>
          </div>
          <div class="clause-text">
            同意
            <span class="clause-link" @click="linkTo('vipxy')"
            >《付费会员服务协议》</span
            >
            <span class="clause-link" @click="linkTo('yszc')"
            >《隐私政策》</span
            >
            <span class="clause-link" @click="linkTo('sytk')"
            >《使用条款》</span
            >
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-dialog
        v-model="stayDialog"
        :before-close="onBeforeClose"
        title="支付确认"
        class="stay-dialog"
        confirmButtonText="好的"
    >
      <div class="img-box">
        <img src="@/assets/images/popup_icon_wenhao.png" alt/>
      </div>

      <div class="text">请确认您是否已支付完成， 点击下方按钮确认</div>
    </van-dialog>
    <van-overlay :show="paySuccess" lock-scroll>
      <div class="pay-success" @click.stop>
        <div class="p-s-title">开通成功</div>
        <div class="p-s-content">
          <div class="p-s-cicon">
            <img src="@a/images/popup_img_yse.png" alt/>
          </div>
          <div class="p-s-ctext">恭喜您开通成功，快去体验吧！</div>
          <div class="p-s-clist">
            重要提示：
            <ul>
              <li>·&nbsp;请妥善保管好开通成功短信：</li>
              <li>
                ·&nbsp;请尽快下载应用，使用收到开通短信的手机号登录即可使用；
              </li>
              <li>·&nbsp;如有使用问题，请联系在线客服</li>
            </ul>
          </div>
        </div>
        <div class="p-s-btn">
          <van-button
              type="primary"
              size="large"
              round
              @click.stop="iKnowIt"
              class="download-btn"
              v-show="download_tag"
          >我知道了
          </van-button
          >
          <van-button
              type="primary"
              size="large"
              round
              class="loading-btn"
              v-show="!download_tag"
          >
            我知道了(
            <van-count-down
                :time="second"
                auto-start
                format="ss 秒"
                @finish="finish"
                ref="countDown"
            />
            )
          </van-button>
        </div>
      </div>
    </van-overlay>
    <div v-html="alipay" ref="alipaywap" style="display: none"></div>
    <van-overlay :show="overlayShow" @click="overlayShow = true">
      <div class="guide-box">
        <img class="img1" src="@a/images/img1.png"/>
        <div>请点击右上角</div>
        <div>
          选择“浏览器”
          <img class="img2" src="@a/images/img2.png"/> 打开
        </div>
        <div>微信/QQ内无法使用</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {chooe, getOrder} from "./indexv2";
import {Toast} from "vant";

export default {
  name: "index",
  data() {
    return {
      text: "",
      actionTag: false,
      payTag: false,
      phone: "",
      rephone: "",
      textPhone: "",
      active: 0,
      stepList: [
        {
          icon: "第一步",
          text: "注册手机号",
          haveLine: true,
          id: 1,
          active: true,
        },
        {
          icon: "第二步",
          text: "立即体验",
          haveLine: true,
          id: 2,
          active: false,
        },
        {
          icon: "第三步",
          text: "查看街景",
          haveLine: false,
          id: 3,
          active: false,
        },
      ],
      dotList: [{id: "a"}, {id: "b"}, {id: "c"}],
      payNum: 0, //支付金额
      checked: 1, //支付方式
      wePay: false, //支持微信支付
      aliPay: false, //支持支付宝支付
      stayDialog: false, //支付确认
      paySuccess: false, //支付成功
      checkTag: true, //手机号 选择
      clauseTag: true, //支付方式协议tag
      alipay: "",
      // copylink:
      //   "https://jiuzhou.yuebei99.com/static/download.html?166d78d04c74c3c27dfd6d3b0c6ac00a",
      download_tag: false,
      overlayShow: false,
      videoLink: require("../../assets/images/750-436V3.mp4"),
      video: {
        width: 0,
        height: 0,
      },
      second: 5000,
      countDown: "",
    };
  },
  watch: {
    stayDialog(value) {
      if (value) {
        this.$uweb.trackEvent("eject_payment_inspect", "版2弹出支付确认弹窗", [
          `${this.$route.path}`,
        ]);
      }
    },
    payTag(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.video.play();
        });
      } else {
        this.$refs.video.pause();
      }
    },
  },
  mounted() {
    this.video.width = window.innerWidth;
    this.video.height = (window.innerWidth / 375) * 70;
  },
  created() {
    this.$uweb.trackEvent("enter_the_home_page", "版2加载", [
      `${this.$route.path}`,
    ]);
    this.getChooe();
    this.isPay();
    this.textPhone = window.localStorage.getItem("userMobilv2") || "";
    var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //在微信中打开
      return (this.overlayShow = true);
    }
    if (
        this.$route.query.load == 1 &&
        ua.indexOf("applewebkit") > -1 &&
        ua.indexOf("mobile") > -1 &&
        ua.indexOf("safari") > -1 &&
        ua.indexOf("linux") === -1 &&
        ua.indexOf("android") === -1 &&
        ua.indexOf("chrome") === -1 &&
        ua.indexOf("ios") === -1 &&
        ua.indexOf("browser") === -1
    ) {
      this.$router.push("download2");
    }
  },
  methods: {
    // 获取数据
    async getChooe() {
      let res = await chooe();
      this.payNum = res.data.price;
      switch (res.data.pay_type + "") {
        case "1":
          this.wePay = true;
          this.aliPay = true;
          break;
        case "2":
          this.wePay = true;
          break;
        case "3":
          this.aliPay = true;
          this.checked = 2;
          break;
      }
    },
    // 输入框输入埋点
    textUweb() {
      this.$uweb.trackEvent("click_enter_address_box", "版2输入框点击", [
        `${this.$route.path}`,
      ]);
    },
    // 是否购买
    isPay() {
      let id = window.localStorage.getItem("order_idv2");
      if (id) {
        this.stayDialog = true;
      }
      let paied = window.localStorage.getItem("paiedv2");
      if (paied) {
        this.paySuccess = true;
      }
    },
    // 点击搜索
    showActionBtn() {
      let phone = window.localStorage.getItem("userMobilv2");
      this.$uweb.trackEvent("eject_the_bind_phone", "版2弹出绑定手机号码弹窗", [
        `${this.$route.path}`,
      ]);
      if (phone) {
        this.payTag = true;
      } else {
        this.actionTag = true;
      }
    },
    // 展示绑定手机号
    showAction() {
      let phone = window.localStorage.getItem("userMobilv2");
      this.$uweb.trackEvent("click_view_street_look", "版2点击查看高清街景", [
        `${this.$route.path}`,
      ]);
      this.$uweb.trackEvent("eject_the_bind_phone", "版2弹出绑定手机号码弹窗", [
        `${this.$route.path}`,
      ]);
      if (phone) {
        this.payTag = true;
      } else {
        this.actionTag = true;
      }
    },
    // 点击手机号输入框
    phoneClick() {
      this.$uweb.trackEvent("click_input_box_phone", "版2点击手机号输入框", [
        `${this.$route.path}`,
      ]);
    },
    // 点击验证手机号输入框
    rePhoneClick() {
      this.$uweb.trackEvent(
          "click_input_confim_phpne",
          "版2点击验证手机号输入框",
          [`${this.$route.path}`]
      );
    },
    // 注册手机号
    sendPhone() {
      if (!this.phone) {
        return Toast("请先注册手机号");
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        return Toast("手机号码输入不正确，请重试");
      }
      if (!this.rephone) {
        return Toast("请输入再次输入手机号");
      }
      if (this.phone != this.rephone) {
        return Toast("两次输入不一致");
      }
      if (!this.checkTag) {
        return Toast("请阅读勾选协议条款");
      }
      this.$uweb.trackEvent("click_obtain_yes_button", "版2注册手机号", [
        `${this.$route.path}`,
      ]);
      this.$uweb.trackEvent("eject_payment_pop_up", "版2弹出付费弹窗", [
        `${this.$route.path}`,
      ]);
      window.localStorage.setItem("userMobilv2", this.phone);
      this.textPhone = window.localStorage.getItem("userMobilv2") || "";
      this.actionTag = false;
      this.payTag = true;
    },
    // 支付
    async payNow() {
      setTimeout(function () {
        Toast("暂未开通h5支付");
      }, 1000);
      // const that = this;
      // if (!this.clauseTag) {
      //   return Toast("请阅读勾选协议");
      // }
      // this.$uweb.trackEvent("click_kaitong_now", "版2点击立即开通", [
      //   `${this.$route.path}`,
      // ]);
      //
      // let params = {
      //   pay_type: `${this.checked}`,
      //   mobile: window.localStorage.getItem("userMobilv2"),
      //   link: window.location.href,
      // };
      // let res = await order(params);
      // window.localStorage.setItem("order_idv2", res.data.order_id);
      // if (this.checked == 1) {
      //   window.location.href = res.data.url;
      // }
      // if (this.checked == 2) {
      //   this.alipay = res.data.url;
      //   this.$nextTick(() => {
      //     that.$refs.alipaywap.children[0].submit();
      //   });
      // }
      //
      // setTimeout(function () {
      //   this.payTag = false;
      //   this.stayDialog = true;
      // }, 1000);
    },
    // 链接
    linkTo(str) {
      let link = "";
      switch (str) {
        case "qxxq":
          link = "https://jiuzhou.yuebei99.com/static/txauth.html";
          break;
        case "yszc":
          link = "https://jiuzhou.yuebei99.com/static/privacypolicy.html";
          break;
        case "vipxy":
          link = "https://jiuzhou.yuebei99.com/static/membershi.html";
          break;
        case "sytk":
          link = "https://jiuzhou.yuebei99.com/static/useterms.html";
          break;
        case "kf":
          link =
              "https://cschat-ccs.aliyun.com/index.htm?tntInstId=_1szVJCi&scene=SCE00011201";
      }
      window.location.href = link;
    },
    // 客服
    kefu() {
      Toast("在线客服仅服务VIP会员哦");
      this.$uweb.trackEvent("clcick_online_service_1", "版2点击客服", [
        `${this.$route.path}`,
      ]);
    },
    // 订单查询
    async onBeforeClose(action, done) {
      if (action == "confirm") {
        let params = {
          order_id: window.localStorage.getItem("order_idv2"),
        };
        let res = await getOrder(params);
        window.localStorage.setItem("order_idv2", "");
        if (res.code == 200) {
          this.paySuccess = true;
          this.download_tag = false;
          this.$refs.countDown.reset();
          window.localStorage.setItem("paiedv2", true);
          this.$copy("166d78d04c74c3c27dfd6d3b0c6ac00a");
          this.$uweb.trackEvent(
              "hui_yaun_zhi_fu_success",
              "版2弹出开通成功弹窗",
              [`${this.$route.path}`]
          );
          setTimeout(function () {
            done();
          }, 200);
        } else if (res.code == 400) {
          Toast("订单支付失败");
          this.$copy("166d78d04c74c3c27dfd6d3b0c6ac00a");
          done();
        } else {
          setTimeout(function () {
            done();
          }, 1000);
        }
      }
    },
    // 关闭action
    onCancel(str) {
      if (str === "phone") {
        this.$uweb.trackEvent("click_payment_close", "版2点击关闭", [
          `${this.$route.path}`,
        ]);
      }
      this.actionTag = false;
      this.payTag = false;
    },
    // 倒计时完成回调
    finish() {
      this.download_tag = !this.download_tag;
    },
    //支付完成 确定
    iKnowIt() {
      this.$copy("166d78d04c74c3c27dfd6d3b0c6ac00a");
      this.$router.push("earth");
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

img {
  width: 100%;
  height: 100%;
}

@media screen and (orientation: portrait) {
  body {
    padding-top: 0;
    padding-top: constant(safe-area-inset-top);
  }
}

@media screen and (orientation: landscape) {
  body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);

    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@main-color: #9289f4;
.kyushu_2 {
  background-color: #050026;
  background-images: url("../../assets/images/v2-x/home_bg.png");
  background-size: 100%;

  .header {
    padding-top: 10px;
    font-size: 12px;
    font-family: PingFangSC;
    color: #b7bcd2;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .logo-box {
      width: 44px;
      height: 44px;
      margin-right: 10px;
    }

    .title-box {
      height: 48px;
      width: 152px;
    }
  }

  .input {
    padding: 0 14px;

    /deep/ .van-cell {
      border-radius: 80px;
      line-height: 60px;
      position: relative;
      overflow: initial;
      font-size: 16px;
      padding: 0;
      padding-left: 16px;
    }

    /deep/ .van-field__control {
      font-size: 16px;
    }

    .input-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, #6f79f4 0%, #2c3790 100%);
      border-radius: 80px;
      padding: 20px 27px;
      font-weight: 500;
      color: #ffffff;
      font-size: 18px;
      line-height: initial;
      display: flex;
      align-items: center;
      justify-items: center;
    }
  }

  .gongge {
    padding: 16px 12px;
  }

  .title {
    padding: 16px 0 16px 16px;
    position: relative;

    .title-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      background: #eca8d0;
      border-radius: 20px;
      color: #fff;
      padding: 8px 48px;
      font-size: 18px;
      font-weight: 600;
      word-break: keep-all;
    }
  }

  .bottom {
    padding: 14px 12px 19px;
  }

  .kefu {
    width: 39px;
    height: 68px;
    position: fixed;
    right: 20px;
    top: 66.7%;
    z-index: 3;
  }

  .pop {
    background: transparent;

    /deep/ .van-action-sheet__header {
      height: 50px;
      background: transparent;

      .van-icon {
        display: none;
      }
    }

    /deep/ .van-action-sheet__content {
      overflow-y: visible;
    }
  }

  .content {
    background: #fff;
    padding: 40px 20px 16px;
    text-align: left;

    .close-img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
      top: 15px;
    }

    .tap {
      background: @main-color;
      border-radius: 20px;
      font-weight: 500;
      color: #ffffff;
      font-size: 14px;
      display: inline-block;
      padding: 6px;
    }

    .step-list {
      display: flex;
      margin-top: 14px;

      .step-item {
        display: flex;
        align-items: center;

        .icon {
          font-size: 14px;
          margin: 0 auto 5px;
          background: #d8d8d8;
          border-radius: 12px;
          padding: 4px 12px;
          text-align: center;
          color: #ffffff;
        }

        .text {
          font-size: 14px;
          color: #090909;
        }

        .line {
          display: flex;
          flex: 1;
          justify-content: space-around;
          align-self: self-start;
          transform: translate(0, 26%);

          .loading {
            .dot {
              width: 9px;
              height: 9px;
              background: #e4e4e4;
              margin: 0 5px;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      .step-item-active {
        .icon {
          background: @main-color;
        }

        .line {
          .loading span {
            background: @main-color !important;
            -webkit-animation: loading 1s infinite alternate;
            animation: loading 1s infinite alternate;
          }

          .loading span:nth-of-type(2) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
          }

          .loading span:nth-of-type(3) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
          }

          .loading span:nth-of-type(4) {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
          }

          @-webkit-keyframes loading {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }

          @keyframes loading {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
        }
      }
    }

    .phone-list {
      margin: 10px 0 0 0;

      /deep/ .van-field__control {
        font-weight: 500;
        color: #090909;
      }

      /deep/ .van-cell__title {
        flex: 2;
        flex-shrink: 0;
        min-width: 80px;
        margin-right: 0;
        padding: 10px 8px;
      }

      /deep/ .van-cell__value {
        flex: 8;
        padding: 10px 16px;
        background: #f3f3f3;
        border-radius: 7px;
      }

      /deep/ .van-cell {
        font-size: 16px;
        padding: 0;
        margin-bottom: 15px;
      }
    }

    /deep/ .van-cell::after {
      display: none;
    }

    /deep/ .van-cell-group::after {
      display: none;
    }

    .phone-btn {
      background: @main-color;
      box-shadow: 0px 3px 9px 0px rgba(146, 137, 244, 0.19);
      border: unset;
      margin-top: 20px;
    }

    .clause {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 85px;

      .clause-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .clause-text {
        color: #828e96;
        font-size: 12px;

        .clause-link {
          color: @main-color;
        }
      }
    }
  }

  .pay-content {
    background: #fff;

    .close-img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
      top: 17px;
    }

    .video-box {
      background: #828282;
      position: relative;
      margin: 0 auto;

      video {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        display: block;
      }

      .video-mode {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 10;
      }
    }

    /deep/ .van-cell__title {
      text-align: left;
    }

    .text-tip {
      width: 34.13%;
      margin: 8px auto 0;
    }

    .pay-num {
      margin: 10px 0 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      .vip_wenb {
        width: 24%;
      }

      .pay-color {
        color: #fb5a7e;
        font-size: 20px;
        transform: translate(0, -2px);
      }
    }

    .ali-or-we {
      .svg-box {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }

      /deep/ .wechat {
        width: 20px;
        height: 20px;
      }

      /deep/ .van-cell {
        margin: 0 10px 12px;
        border-radius: 500px;
        background: #f3f3f3;
        width: inherit;
        border: 2px solid transparent;
      }

      /deep/ .van-cell-group::after {
        border-width: 0;
      }

      .select {
        border: 2px solid @main-color;
      }
    }

    .pay-btn {
      //width: 80%;
      border: 0;
      background: linear-gradient(180deg, #ffac81 0%, #ffd84b 100%);
    }

    .clause {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 46px;

      .clause-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .clause-text {
        font-size: 12px;

        .clause-link {
          color: @main-color;
        }
      }
    }
  }

  .stay-dialog {
    /deep/ .van-dialog__header {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f2c2c;
    }

    /deep/ .van-dialog__content {
      .img-box {
        width: 47px;
        height: 47px;
        margin: 15px auto;
      }

      .text {
        width: 60%;
        margin: 0 auto;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2f2c2c;
      }
    }

    /deep/ .van-hairline--top::after {
      border-top-width: 0;
    }

    /deep/ .van-dialog__footer {
      .van-button {
        width: 60%;
        background: @main-color;
        border-radius: 26px;
        flex: unset;
        margin: 10px auto 18px;
        color: #ffffff;
      }
    }
  }

  .pay-success {
    width: 70%;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;

    .p-s-title {
      font-size: 18px;
      font-weight: 500;
      color: #302e51;
      margin-bottom: 10px;
      text-align: center;
    }

    .p-s-content {
      .p-s-cicon {
        width: 93px;
        height: 83px;
        margin: 0 auto 10px;
      }

      .p-s-ctext {
        text-align: left;
        font-size: 18px;
        font-family: PingFangSC;
        color: #302e51;
        margin-bottom: 10px;
      }

      .p-s-clist {
        display: flex;
        flex-flow: column;
        text-align: left;
        font-size: 14px;
        font-family: PingFangSC;
        color: #fb5a7e;
        line-height: 20px;
      }
    }

    .p-s-btn {
      width: 90%;
      margin: 28px auto 0;

      .download-btn {
        background: @main-color;
        border: unset;
      }

      .loading-btn {
        background: #bdbdbd;
        border: unset;

        /deep/ .van-button__text {
          display: flex;

          .van-count-down {
            color: #fff;
          }
        }
      }
    }
  }
}

/deep/ .van-action-sheet__close {
  z-index: 99;
}

.wrapper {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;

  div {
    align-self: flex-end;
    position: relative;
    top: 0.5em;
  }

  .share_icon {
    width: 5em;
    height: 5em;
  }
}

.van-overlay {
  z-index: 1000;
  text-align: right;
  font-size: 14px;

  .guide-box {
    color: white !important;
    margin-right: 10px;

    .img1 {
      width: 112px;
      height: 148px;
    }

    .img2 {
      width: 20px;
      height: 20px;
    }
  }
}
</style>