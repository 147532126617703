<template>
  <div class="firm">
    <Navbar></Navbar>
    <div class="container">
      <div class="rowBox">
        <div id="description">
          <h1>关于悦北</h1>
          <p>
            深圳悦北科技有限公司（简称：悦北科技）成立于2019年，一步一个脚印，发展成为一家互联网科技产业；悦北科技以打造生活服务、文旅、康养、民生等发展理念成为一家软件技术研发企业，总部位于深圳市宝安区。
          </p>
        </div>
      </div>
      <div class="rowBox1">
        <div id="description1">
          <h1>关于公司</h1>
          <p>
            悦北科技致力于开发个人用户热衷的互联网APP研发、应用，并在国内海外率先推出基于地图基础上，提高人们生活质量应用平台产品，是国内领先的软件开发服务商。<br>公司客户分布广泛，涉及各个人群领域，同时悦北科技正在致力为更多的用户打造，足不出户走天下的生态蓝图。<br>2019年，悦北科技基于垂直细分的市场，为了真正解决互联网行业地图产品不细分等众多难题，实现
            “让人在家坐拥看全景”的梦想，打造了全景地图拳头产品，并且将在5年内打造出国内外地图板块Top10精品产品。<br>
            悦北科技是一个轻盈、活力、智慧的互联网研发服务平台。未来，悦北科技将不断完善和优化悦北的企业愿景，按照软件开发+公共服务+会员至上的协同发展的方向，投入更多的精力致力于互联网的研究与发展。<br>悦北科技始终秉承“实现理想、贡献社会”的企业宗旨，践行“真诚、平等、勤奋、创新”的企业精神，贯彻“传道、授业、解惑”的企业使命，亲切、开放、严谨的公司文化和科学规范的管理，凝聚大量优秀的人才。<br>
            悦北科技拥有一支由优秀、资深的IT专家组成的技术团队，公司CEO毕业于美国耶鲁大学，在技术研发管理具有独特的管理方式。公司在产品开发、推广和维护方面默契合作，深刻理解以客户为中心的含义，将服务贯穿于公司运作和管理的每一个细节，赢得客户广泛的信任和支持。
          </p>
        </div>
      </div>
    </div>

    <div class="dibu">
      <div class="dibunr">
        <div>
          <img src="@a/images/dingw_icon.png" alt/>
          <p>
            2019
            <span>年</span>
          </p>
          <span>悦北科技成立</span>
        </div>

        <div class="second">
          <img src="@a/images/icon_chanp.png" alt/>
          <p>
            1个亿
            <span>+</span>
          </p>
          <span>产品得到用户海量下载</span>
        </div>
        <div>
          <img src="@a/images/icon_tuandui.png" alt/>
          <p>90后</p>
          <span>年轻活力团队</span>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
.firm {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url(../assets/images/Group1.png);
  background-repeat: no-repeat;
  background-size: 100vw 650px;
  background-position: top left;
}

.container {
  overflow-y: hidden;
}

.rowBox {
  width: 810px;
  overflow: hidden;
  margin: 0 auto;
  margin-right: 115px;
}

.rowBox h1 {
  font-size: 40px;
  color: #fff;
  padding-top: 110px;
  padding-left: 35%;
}

.rowBox p {
  /* width: 500px; */
  padding: 10px 0;
  color: #fff;
  font-size: 20px;
  padding-left: 35%;
}

.rowBox1 {
  padding-top: 230px;
  margin: 0 auto;
  text-align: center;
  width: 1142px;
}

.rowBox1 #description1 {
  text-align: left;
  width: 1142px;
}

.rowBox1 #description1 h1 {
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
}

.rowBox1 #description1 p {
  display: block;
  width: 1142px;
  font-size: 20px;
  color: #8c8c8c;
  padding-top: 50px;
  padding-bottom: 20px;
}

.dibu {
  width: 100%;
  height: 400px;
  background-image: url(../assets/images/bg_1_1.png);
  background-repeat: no-repeat;
  background-size: 100vw 400px;
  background-position: bottom left;
}

.dibu .dibunr {
  width: 60%;
  margin: 0 auto;
  margin-top: 70px;
}

.dibu .dibunr div {
  width: 26%;
  height: 240px;
  float: left;
  border: 1px solid #fff;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
  padding-top: 10px;
}

.dibu .dibunr div img {
  height: 163px;
}

.dibu .dibunr div p {
  padding-top: 10px;
  font-size: 20px;
  color: #404040;
}

.dibu .dibunr div p span {
  font-size: 16px;
}

.dibu .dibunr div span {
  font-size: 16px;
  color: #404040;
}

.dibu .dibunr .second {
  margin: 0 83px;
}

/* 媒体查询适配 */
@media screen and (max-width: 1200px) {
  .firm {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-image: url(../assets/images/gybf.png);
    background-repeat: no-repeat;
    background-size: 100% 442px;
    background-position: top left;
  }

  .rowBox {
    width: 96%;
    overflow: hidden;
    margin: 0;
    /* margin-right: 115px; */
  }

  .rowBox h1 {
    font-size: 30px;
    color: #fff;
    padding-top: 30px;
    padding-left: 17px;
  }

  .rowBox p {
    /* width: 380px; */
    padding: 10px 0;
    color: #fff;
    font-size: 16px;
    padding-left: 15px;
    padding-top: 15px;
  }

  .rowBox1 #description1 h1 {
    text-align: center;
    font-size: 30px;
  }

  .rowBox1 {
    padding-top: 170px;
    text-align: left;
    width: 96%;
  }

  .rowBox1 #description1 h1 {
    margin: 0 auto;
    text-align: left;
    font-size: 30px;
    padding-left: 30px;
  }

  .rowBox1 #description1 {
    text-align: center;
    width: 100%;
  }

  .rowBox1 #description1 p {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #8c8c8c;
    padding-top: 0px;
    /* padding-bottom: 20px; */
    /* padding-left: 25px; */
  }

  .dibu {
    width: 100%;
    height: 200px;
    background-image: url(../assets/images/bg_1_1.png);
    background-repeat: no-repeat;
    background-size: 100% 200px;
    background-position: bottom left;
  }

  .dibu .dibunr {
    width: 80%;
    margin: 0 auto;
    margin-top: 45px;
  }

  .dibu .dibunr div {
    width: 28%;
    height: 127px;
    float: left;
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center;
    background-color: #fff;
    padding-top: 10px;
  }

  .dibu .dibunr div img {
    height: 50px;
  }

  .dibu .dibunr div p {
    padding-top: 0px;
    font-size: 14px;
    color: #404040;
  }

  .dibu .dibunr div p span {
    font-size: 8px;
  }

  .dibu .dibunr div span {
    font-size: 8px;
    color: #404040;
  }

  .dibu .dibunr .second {
    margin: 0 15px;
    overflow-y: hidden;
    /* margin-left: 10px; */
  }
}
</style>