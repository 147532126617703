export default {
    productList: [
        {
            id: 6,
            img: require("@a/images/3dsjjj_img.png"),
            title: "全景VR高清地图",
            dec: "一部手机就可以让你看遍世界了，世界任何角落都可以快速搜索到，可让您真切体验一下从城市上方飞过的感觉；3D地图实景功能让您尽情游览，完全以3D街景方式到达你想去的城市，集成到地球中的街景视图可让您穿街走巷、品味世界，您可以浏览到包括公路、边界、地点等在内的各种图层",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/3dword.png")
        },
        {
            id: 2,
            img: require("@a/images/lovehelp.png"),
            title: "恋爱帮帮忙",
            dec: "教你如何聊天的恋爱宝典。帮助恋爱中的男女在聊天时用更撩人的话术回复对方.",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 17,
            img: require("@a/images/speedsatellite.png"),
            title: "极速卫星导航",
            dec: "极速卫星导航给人们带来准确的导航引导，是一款为用户提供实景导航的应用软件，可帮助每个有导航需求的用户，为您们提供精确的位置和智能实时规划目的地路线。并且使用AR技术为您提供行业先进的导航技术。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 16,
            img: require("@a/images/travelearth_image.png"),
            title: "悦北地球探索3D",
            dec: "悦北地球探索3D软件一款让地球仪更好玩有趣的应用，利用AR、3D互动、语音等技术，带给用户一种全新的产品体验，用高新技术赋能产品，从而提升品牌在用户心中的口碑。内容丰富，包括十八大内容主题，有世界每个国家和中国每个省的知识介绍，有世界上国家著名的建筑，也有远古的恐龙世界，更有天文地理的自然奇观，足不出户就可以体验到世界全景景观，还有趣味的答题库。让小孩爱上天文地理。同时还有娱乐的拼图模块，通过互动的方式让小孩快速的学到地图知识。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 15,
            img: require("@a/images/ar_earth_navigation.png"),
            title: "AR地球实景导航",
            dec: "给人们带来准确的导航方位，是一款集成主流地图的聚合类地图应用，可帮助各行业有地图需求的用户，涵盖游客、司机、教师、学生、地理位置收集员等。 独有的卫星雷达功能。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 14,
            img: require("@a/images/travelearth_image.png"),
            title: "地球探索3D",
            dec: "地球探索3D软件一款让地球仪更好玩有趣的应用，利用AR、3D互动、语音等技术，带给用户一种全新的产品体验，用高新技术赋能产品，从而提升品牌在用户心中的口碑。内容丰富，包括十八大内容主题，有世界每个国家和中国每个省的知识介绍，有世界上国家著名的建筑，也有远古的恐龙世界，更有天文地理的自然奇观，足不出户就可以体验到世界全景景观，还有趣味的答题库。让小孩爱上天文地理。同时还有娱乐的拼图模块，通过互动的方式让小孩快速的学到地图知识。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 13,
            img: require("@a/images/livepilot_img.png"),
            title: "3D实景导航",
            dec: "3D实景导航向您展示世界上最令人震撼的地方和位置。一起发现这颗蓝色星球的有趣事物和绝美风景！丰富多彩的地球图层、舒适的界面和精确的地球地形模型，一键开启，精确导航，实时定位精准，目的地详细位置引导，问路不求人！为用户提供智能路线规划及多种出行方式组合的方案、地点查询等出行相关服务的平台，支持全局搜索交互，行业领先的AR实景导航等全新交互模式，更优精准服务覆盖全程。致力于为用户提供更准确、更丰富、更易用的出行服务。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 12,
            img: require("@a/images/zy_img.png"),
            title: "追影",
            dec: "追影是一款专注于艺术文化气息浓郁的图片编辑工具软件，你不需要很高的艺术天赋，就可以根据你喜欢的文字，选择你喜欢的图片比例、艺术风格和艺术家派系，自动生成如同梵高、达芬奇、莫奈等大师级风格的艺术作品，只要简单手指操作点一点，一段文字就能生成一张精美的艺术图片。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 11,
            img: require("@a/images/kcc_img.png"),
            title: "快查查",
            dec: "快查查是一款全方位关注中老年人生活的app软件，给中老年人的生活中给予一定的便捷。软件中具即有健康码、实时公交、网上预约挂号这类便民生活服务，还有手电筒、放大镜以及各种热门软件的使用教程这类的便捷小工具。也能轻松教父母长辈使用手机，解决手机使用问题，关怀长辈的应用，还有更多方便使用的功能，方便长辈快速使用手机。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/kuaichacha.png")
        },
        {
            id: 10,
            img: require("@a/images/zjgqjj_img.png"),
            title: "九州高清街景",
            dec: "九州高清街景是为您提供全面的地图导航功能、高清街景地图效果、3D VR看遍全世界美景的应用，采用了百度全地图技术，可满足各行业地理信息的需求",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/nine.png")
        },
        {
            id: 9,
            img: require("@a/images/kkgqjj_img.png"),
            title: "快看高清街景",
            dec: "快看高清街景是为您提供全面的地图导航功能、高清街景地图效果、3D VR看遍全世界美景的应用，采用了百度全地图技术，可满足各行业地理信息的需求",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/kuaikan.png")
        },
        {
            id: 8,
            img: require("@a/images/aysjjq_img.png"),
            title: "遨游世界街景",
            dec: "世界那么大，总想去看看。<br/>打开遨游世界街景App，搜索你想要查看的地方。<br/>高清卫星图像，支持无限缩放。随时随地回味家乡味道，足不出户领略世界风景。<br/>出行前的攻略助手，提前查看想去的地方。<br/>遨游世界街景地图是您探索世界的好伴侣，真正做到国内外全覆盖街景，软件集成了多个强大的高清地图系统，支持2D和3D模式切换、自动定位跟随，智能导游多语言景点讲解。包含省市、县、村各级地图，是您真正需要的全球街景地图导航应用。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/aoyou.png")
        },
        {
            id: 7,
            img: require("@a/images/vr_img.png"),
            title: "vr地球街景",
            dec: "人的一生中至少要有两次冲动，一次为了奋不顾身的爱情，一次为了说走就走的旅行。<br/>世界那么大，都想去看看。VR地球全景，带你从新视角看世界。<br/>3d卫星街景地图查看世界景点<br/>高清3d全景卫星地图，千里之外看家乡。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/vrEarth.png")
        },
        {
            id: 6,
            img: require("@a/images/3dsjjj_img.png"),
            title: "3D世界街景地图",
            dec: "一部手机就可以让你看遍世界了，世界任何角落都可以快速搜索到，可让您真切体验一下从城市上方飞过的感觉；3D地图实景功能让您尽情游览，完全以3D街景方式到达你想去的城市，集成到地球中的街景视图可让您穿街走巷、品味世界，您可以浏览到包括公路、边界、地点等在内的各种图层",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/3dword.png")
        },
        {
            id: 5,
            img: require("@a/images/tnbsq_img.png"),
            title: "特牛变声器",
            dec: "这是一款有趣好玩多趣味的变声器软件，可以让你的声音丰富多彩。<br/>整蛊变声，聊天变声，游戏开黑变声。让你的枯燥无聊的生活充满乐趣",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/teniu.png")
        },
        {
            id: 1,
            img: require("@a/images/dttp.png"),
            title: "风影-图片美化大师",
            dec: "老照片修复：上传模糊老照片，AI修复自动生成修复版高清照片；动态照片：缅怀故人？不如上传旧照片，让照片人物动起来！动漫相机：解锁多种二次元形象，带你走进动漫世界。[AI换装·照片唱歌]【旗袍佳人】民国风情，中式旗袍，婀娜身姿尽显，千种风情【照片唱歌】AI视频融合技术，一键帮你生成专属怀旧的照片唱歌视频，重温经典。",
            btnType: 1,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/images/e844ccd18369c731504c636feba721f.png")
        },
        // {
        //     id: 1,
        //     img: require("@a/images/stsj@2x.png"),
        //     title: "探寻-智慧出行",
        //     dec: "知识无门槛，一拍晓世界。软件主要是解决生活中人们常常遇到的知识盲区，想提高办公效率，对新事物好奇却不了解的需求。通过使用APP来检测图像的类型，以及获取图像所含有的一些信息。人们只需用相机拍摄一张照片，点击识别即可得到结果。AI智能识别图片，中英文、动物植物、网络图片，果蔬菜品等等，真正做到智能智慧识图，随时随地解决知识焦虑。",
        //     btnType: 1,//1 ios 2 android
        //     qrCodeShow: false,
        //     qrCodeUrl: require("@a/images/e844ccd18369c731504c636feba721f.png")
        // },
        // {
        //     id: 1,
        //     img: require("@a/images/stsj.png"),
        //     title: "高清世界街景地图",
        //     dec: "足不出户即享各地街景，高清卫星实拍地图。还有热门景区，高楼大厦，随时随地领略不同风光!您可通过操作手机角度实时转动实景地图角度，部分地图支持方向变换。如您可向东南西北前进，观察临近风光。<br/>[3D/2D地图模式]：提供2D和3D两种地图模式，随心查看。<br/>[VR景点]：VR360°全景沉浸式游览，带你领略世界人文奇观和自然美景。看风景，涨知识。<br/>[VR实景视频]：VR动态视频，360度刻画真实世界",
        //     btnType: 1,//1 ios 2 android
        //     qrCodeShow: false,
        //     qrCodeUrl: require("@a/images/e844ccd18369c731504c636feba721f.png")
        // },
        {
            id: 2,
            img: require("@a/images/ppst.png"),
            title: "拍拍识图",
            dec: "拍照认识世界，每一天都很新鲜。<br/>“拍拍识图识字”APP使用AI识别图片，文字、Logo、动物、植物、果蔬，红酒等等统统可以识别。只需用相机拍一张照片，或者从相册中选择要识别的图片，即可得到结果。无限次，无限功能使用，解决您的知识焦虑！ 万能识别",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/images/b5492324054ff190792daa40528a560.png")
        },
        {
            id: 3,
            img: require("@a/images/quming.png"),
            title: "起名取名字",
            dec: "你的名字值多少分？一键获取名字好坏和评分！<br/>轻轻松松就能取得好名字，简简单单就能为名字测试打分，名字运程还能帮你解析名字的意涵，让你对自己的名字奥秘了如指掌！",
            btnType: 1,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/images/3e42f88262a5b037bd5ce1a0bcb0a42.png")
        },
        {
            id: 4,
            img: require("@a/images/yh.png"),
            title: "樱花小组件",
            dec: "“樱花小组件”是一款好用的iphone工具类应用和针对ios14系统打造的美化桌面的小工具。<br/>彻底告别过去满屏都是APP图标的桌面，我们深知时间是世界唯一稀缺的资源，因此将让您第一时间可以获取到天气、星座运势、微博热搜、倒数日、纪念日等信息。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/images/02b03e2080a1b739324652b81ca5ee7.png")
        }
    ]
}