<template>
  <div class="product">
    <navbar :class="isScroll == true ? 'navbar' : ''"></navbar>
    <img class="imagess" src="@a/images/chanp_nr@3x.png" alt />
    <div class="container-padding-top">
      <div class="container">
        <h1 class="mingzi">
          我们有很多
          <br />好玩的应用
        </h1>
        <div
          class="app"
          v-for="(item, index) in list"
          :key="item.id"
          :class="index % 2 == 0 ? 'app-l' : 'app-r'"
        >
          <div class="max-box">
            <div class="image">
              <img class="phone" :src="item.img" alt />
              <img class="xiatu" src="@a/images/zhiwu_2.png" alt />
            </div>
            <div class="text">
              <h1>{{ item.title }}</h1>
              <div class="text-c">
                <p class="text-f" v-html="item.dec"></p>
              </div>
              <div
                class="btn"
                :class="item.btnType == 1 ? 'btn-ios' : 'btn-android'"
                @click="clickbtn(item)"
                style="cursor: pointer"
              >
                立即体验
<!--                {{ item.btnType == 1 ? "ios下载" : "安卓下载" }}-->
              </div>
              <img
                v-if="item.qrCodeShow && item.qrCodeUrl"
                class="qrcode"
                :src="item.qrCodeUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="footer">
      <div class="rights">
        <img src="https://softin-tech.oss-cn-shanghai.aliyuncs.com/img/logo_white.png" alt />
        <p>
          <a href="http://www.miitbeian.gov.cn/" target="_blank">粤ICP备19014276号</a>
        </p>
      </div>

      <ul class="links">
        <li>
          <a href="mailto:contact@softin-tech.com">商务合作</a>
        </li>
        <li>
          <a href="mailto:contact@softin-tech.com">意见反馈</a>
        </li>
        <li>
          <a href="/about">关于我们</a>
        </li>
        <li>
          <a href="mailto:contact@softin-tech.com">联系我们</a>
        </li>
      </ul>
    </div>-->
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import config from "./product";
export default {
  data() {
    return {
      list: config.productList,
      isScroll: "",
    };
  },
  components: {
    Navbar,
    Footer,
  },
  methods: {
    clickbtn() {
      this.$router.push("/nine2");
      // item.qrCodeShow = !item.qrCodeShow;
    },
  },
};
</script>
<style scoped lang='less'>
.product {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url(../assets/images/chanp_bg@3x.png);
  background-repeat: no-repeat;
  background-size: 100vw 600px;
  background-position: top left;
  position: relative;
}
.imagess {
  position: absolute;
  left: 55%;
  top: 100px;
}
.container {
  margin: 0 auto;
  width: 100vm;
  .mingzi {
    font-size: 60px;
    color: #fff;
    padding-left: 260px;
    margin-bottom: 344px;
  }
  .app {
    width: 100%;
    background-image: url(../assets/images/bg.png);
    background-repeat: no-repeat;
    background-size: 100vw 280px;
    background-position: center;
    .max-box {
      max-width: 1410px;
      margin: 0 auto;
      padding: 0 135px;
      position: relative;
      .image {
        position: relative;
        flex-shrink: 0;
        overflow-x: unset !important;
        width: 340px;
        .phone {
          width: 340px;
        }
        .xiatu {
          position: absolute;
          width: 156px;
          bottom: 0;
        }
      }
      .text {
        position: absolute;
        padding-top: 70px;
        overflow: unset;
        h1 {
          font-size: 80px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #000;
          padding: 0;
        }
        .text-c {
          padding: 10px 0;
          height: 150px;
          p {
            color: #8c8c8c;
            font-weight: 400;
            line-height: 30px;
            font-size: 20px;
          }
        }
        .qrcode {
          width: 280px;
          position: absolute;
          top: 186px;
          left: 310px;
        }
        .btn {
          width: 323px;
          height: 176px;
          text-align: center;
          color: #fff;
          background-repeat: no-repeat;
          background-position: center;
          line-height: 176px;
          font-size: 24px;
          background-size: 100%;
        }
        .btn-ios {
          background-image: url("../assets/images/icon_btn@3x.png");
        }
        .btn-android {
          background-image: url("../assets/images/android_btn@3x.png");
        }
      }
    }
  }
  .app-l {
    .max-box {
      .image {
        margin-right: 90px;
        .xiatu {
          right: -64px;
        }
      }
      .text {
        top: 0;
        right: 135px;
        left: 565px;
      }
    }
  }
  .app-r {
    .max-box {
      display: flex;
      justify-content: flex-end;
      .image {
        margin-left: 90px;
        .xiatu {
          left: -100px;
        }
      }
    }
    .text {
      top: 0;
      left: 135px;
      right: 565px;
    }
  }
}

/* 媒体查询适配 */
@media (max-width: 1200px) {
  #footer {
    display: none;
  }
}
@media screen and (min-width: 760px) and (max-width: 1200px) {
  .product {
    position: relative;
    width: 100vw;
    overflow: unset;
    background-image: url(../assets/images/chanp_bg@3x.png);
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    background-position: top left;
    position: relative;
    .imagess {
      width: 200px;
      height: 200px;
      position: absolute;
      left: 50%;
      top: 150px;
    }
    .mingzi {
      font-size: 30px;
      color: #fff;
      padding-left: 17px;
      padding-top: 30px;
    }
    .app {
      background-image: url(../assets/images/bg.png);
      background-repeat: no-repeat;
      background-size: 100vw 280px;
      background-position: center;
      .max-box {
        margin: 0 auto;
        padding: 0 135px;
        position: relative;
        .image {
          position: relative;
          flex-shrink: 0;
          overflow-x: unset !important;
          width: 20vw;
          .phone {
            width: 20vw;
          }
          .xiatu {
            position: absolute;
            width: 10vw;
            bottom: 0;
          }
        }
        .text {
          position: absolute;
          padding-top: 70px;
          overflow: unset;
          h1 {
            font-size: 40px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #000;
            padding: 0;
          }
          .text-c {
            padding: 10px 0;
            p {
              color: #8c8c8c;
              font-weight: 400;
              line-height: 30px;
              font-size: 16px;
            }
          }
          .qrcode {
            width: 280px;
            position: absolute;
            top: 46px;
            left: 20vw;
          }
          .btn {
            width: 20vw;
            height: 10vw;
            text-align: center;
            color: #fff;
            background-repeat: no-repeat;
            background-position: center;
            line-height: 10vw;
            font-size: 24px;
            background-size: 100%;
          }
          .btn-ios {
            background-image: url("../assets/images/icon_btn@3x.png");
          }
          .btn-android {
            background-image: url("../assets/images/android_btn@3x.png");
          }
        }
      }
    }
    .app-l {
      .max-box {
        .image {
          margin-right: 90px;
          .xiatu {
            right: -64px;
          }
        }
        .text {
          top: 0;
          right: 135px;
          left: calc(135px + 20vw + 90px);
        }
      }
    }
    .app-r {
      .max-box {
        .image {
          margin-left: 90px;
          .xiatu {
            left: -100px;
          }
        }
      }
      .text {
        top: 0;
        left: 135px;
        right: calc(135px + 20vw + 90px);
      }
    }
  }
}

/* 375适配 */
@media screen and (max-width: 759px) {
  .product {
    position: relative;
    width: 100vw;
    overflow-x: unset;
    background-image: url(../assets/images/chanp_bg@3x.png);
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    background-position: top left;
    position: relative;
  }
  .imagess {
    width: 200px;
    height: 200px;
    position: absolute;
    left: unset;
    right: 0;
    top: 150px;
  }
  .container {
    .mingzi {
      font-size: 20px;
      color: #fff;
      padding-left: 17px;
    }
    .app {
      .max-box {
        display: block;
        padding: 0 20px;
        .image {
          left: 0;
          margin-left: 0;
          margin: 0 auto;
          .xiatu {
            display: none;
          }
        }
        .text {
          position: relative;
          left: 0;
          right: 0;
          padding-top: 20px;
          h1 {
            font-size: 20px;
          }
          .text-c {
            height: unset;
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
          .btn {
            width: 200px;
            height: 100px;
            line-height: 100px;
            display: inline-block;
            margin: 0 auto;
          }
          .qrcode {
            top: unset;
            bottom: 110px;
            left: 30px;
            width: 150px;
          }
        }
      }
    }
  }
}
</style>