<template>
  <div class="kyushu_1">
    <all-header :version="'v1.0.0'" :projectName="'AR地球实景导航'" :project="'arearth'"
                :developer="'深圳悦北科技有限公司'" color="#00182e"></all-header>
    <div class="back" @click="getBlack()">
      <img class="back-item" src="@/assets/images/arearth/back.png" alt/>
      <span class="back-text">开启VR导航功能</span>
    </div>
    <div class="swipe-box">
      <van-swipe
          class="my-swipe"
          :autoplay="1000"
          indicator-color="white"
          :show-indicators="false"
      >
        <van-swipe-item>
          <img class="img-item" src="@/assets/images/arearth/carousel_1.png" alt/>
        </van-swipe-item>
        <van-swipe-item>
          <img class="img-item" src="@/assets/images/arearth/carousel_2.png" alt/>
        </van-swipe-item>
        <van-swipe-item>
          <img class="img-item" src="@/assets/images/arearth/carousel_3.png" alt/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="pay-content">
      <div class="desc">
        <div>即刻享受限时特价优惠</div>
        <div><span class="free">免费</span>试用一整年！</div>
        <div>￥49.9 <span class="scribe">原价￥89</span></div>
      </div>
      <van-radio-group v-model="checked" direction="horizontal" class="pay_type">
        <div class="pay_center">
          <div class="pay_state">
            <span class="state_title">
              <svg-icon iconClass="wechat" class="svg-box"></svg-icon>
              <span>微信支付</span>
            </span>
            <van-radio name="1" class="radio" aria-checked="true">
            </van-radio>
          </div>
          <div class="pay_state">
            <span class="state_title">
              <svg-icon iconClass="vip_alipay" class="svg-box"></svg-icon>
              <span>支付宝</span>
            </span>
            <van-radio name="2" class="radio">
            </van-radio>
          </div>
        </div>

      </van-radio-group>
      <div class="pay-btn" @click="payNow">

      </div>
      <div class="clause">
        <div class="clause-icon" @click="clauseTag = !clauseTag">
          <svg-icon
              iconClass="checkbox"
              class="clause-svg"
              v-if="!clauseTag"
          ></svg-icon>
          <svg-icon
              iconClass="vip_alipay_selected"
              class="clause-svg"
              v-if="clauseTag"
          ></svg-icon>
        </div>
        <div class="clause-text">
          我已阅读并同意
          <span class="clause-link" @click="linkTo('vipxy')"
          >《开启AR导航协议》</span
          >
          <span class="clause-link" @click="linkTo('yszc')"
          >《隐私政策》</span
          >
        </div>
      </div>
    </div>
    <van-dialog
        v-model="stayDialog"
        :before-close="onBeforeClose"
        title="支付确认"
        class="stay-dialog"
        confirmButtonText="好的"
    >
      <div class="img-box">
        <img src="@/assets/images/popup_icon_wenhao.png" alt/>
      </div>

      <div class="text">请确认您是否已支付完成， 点击下方按钮确认</div>
    </van-dialog>
    <van-overlay :show="paySuccess" lock-scroll>
      <div class="pay-success" @click.stop>
        <div class="p-s-title">恭喜</div>
        <div class="p-s-content">
        </div>
        <div class="load-text">
          <span
              class="load-text-value-one">已成功开通会员，点击下方“下载软件”。下载后打开软件，如果有疑问请在设置中联系客服处理。</span><br>
          <span class="load-text-value-two">如无法下载请点击”
            <span class="copy" @click="copy">复制链接</span>“然后在浏览器中粘贴进行下载</span>
        </div>
        <div class="p-s-btn">
          <van-button
              type="primary"
              size="large"
              round
              @click.stop="downLoad"
              class="download-btn"
              v-if="!download_tag"
          >立即下载
          </van-button
          >
          <van-button
              loading
              type="primary"
              size="large"
              round
              loading-text="下载中..."
              class="loading-btn"
              v-if="download_tag"
          />
        </div>
      </div>
    </van-overlay>
    <div v-html="alipay" ref="alipaywap" style="display: none"></div>
  </div>
</template>
<script>
import allHeader from "@/components/header";
import {order, getOrder, getGoods} from "./index";
import {Toast} from "vant";

export default {
  name: "index",
  components: {
    allHeader,
  },
  data() {
    return {
      text: "",
      actionTag: true,
      payTag: true,
      phone: "",
      rephone: "",
      textPhone: "",
      active: 0,
      stepList: [
        {
          icon: "第一步",
          text: "注册手机号",
          haveLine: true,
          id: 1,
          active: true,
        },
        {
          icon: "第二步",
          text: "下载应用",
          haveLine: true,
          id: 2,
          active: false,
        },
        {
          icon: "第三步",
          text: "查看街景",
          haveLine: false,
          id: 3,
          active: false,
        },
      ],
      dotList: [{id: "a"}, {id: "b"}, {id: "c"}],
      payNum: 0, //支付金额
      checked: "1", //支付方式
      wePay: true, //支持微信支付
      aliPay: true, //支持支付宝支付
      stayDialog: false, //支付确认
      paySuccess: false, //支付成功
      checkTag: true, //手机号 选择
      clauseTag: true, //支付方式协议tag
      alipay: "",
      copylink:
          "https://befun-static.oss-cn-shenzhen.aliyuncs.com/arearth/ArRealityNavi_v1.0.9_h5_release_0831_109_jiagu_sign.apk",
      download_tag: false,
      overlayShow: false,
      priceList: [],
    };
  },
  watch: {
    stayDialog(value) {
      if (value) {
        this.$uweb.trackEvent("eject_payment_inspect", "版1弹出支付确认弹窗", [
          `${this.$route.path}`,
        ]);
      }
    },
  },
  mounted() {
  },
  created() {
    this.$uweb.trackEvent("enter_the_home_page", "版1加载", [
      `${this.$route.path}`,
    ]);
    this.isPay();
    this.getGoodsDetail();
    this.textPhone = window.localStorage.getItem("userMobil") || "";
    var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //在微信中打开
      return (this.overlayShow = true);
    }
    // if (
    //     this.$route.query.load == 1 &&
    //     ua.indexOf("applewebkit") > -1 &&
    //     ua.indexOf("mobile") > -1 &&
    //     ua.indexOf("safari") > -1 &&
    //     ua.indexOf("linux") === -1 &&
    //     ua.indexOf("android") === -1 &&
    //     ua.indexOf("chrome") === -1 &&
    //     ua.indexOf("ios") === -1 &&
    //     ua.indexOf("browser") === -1
    // ) {
    //   this.$router.push("download");
    // }
  },
  methods: {
    //获取价格
    async getGoodsDetail() {
      let res = await getGoods();
      let list = res.data;
      list.forEach((item) => {
        item.active = false;
        if (item.is_default == 1) {
          item.active = true;
        }
      });
      this.priceList = res.data;
    },
    // 输入框输入埋点
    textUweb() {
      this.$uweb.trackEvent("click_enter_address_box", "版1输入框点击", [
        `${this.$route.path}`,
      ]);
    },
    // 是否购买
    isPay() {
      let paied = window.localStorage.getItem("paied");
      if (paied) {
        this.paySuccess = true;
      } else {
        let id = window.localStorage.getItem("order_id");
        if (id) {
          this.onBeforeClose()
          // this.stayDialog = true;
        }
      }
    },
    // 点击搜索
    showActionBtn() {
      this.payTag = true;
    },
    // 展示绑定手机号
    showAction() {
      this.payTag = true;
    },

    // 切换选择价格
    changePrice(item) {
      this.priceList.forEach((eve) => {
        eve.active = false;
        if (item.member_price === eve.member_price) {
          eve.active = true;
        }
      });
    },
    // 支付
    async payNow() {
      const that = this;
      if (!that.clauseTag) {
        return Toast("请阅读勾选协议");
      }
      this.$uweb.trackEvent("click_kaitong_now", "版1点击立即开通", [
        `${that.$route.path}`,
      ]);

      let params = {
        pay_type: `${that.checked}`,
        encoding: 999999999,
        pay_class: "h5",
        order_price: 49.9,
        goods_day: 365,
        clickId: window.localStorage.getItem("clickid"),
      };
      let res = await order(params);
      window.localStorage.setItem("order_id", res.data.order_sn);
      if (this.checked == 1) {
        window.location.href = res.data.h5_url;
      }
      if (this.checked == 2) {
        that.alipay = res.data.body
        const div = document.createElement('div');
        div.innerHTML = res.data.body
        document.body.appendChild(div);
        document.forms[0].submit();
      }
      setTimeout(function () {
        this.payTag = false;
        this.stayDialog = true;
      }, 1000);
    },
    // 链接
    linkTo(str) {
      let link = "";
      switch (str) {
        case "qxxq":
          link = "https://jiuzhou.yuebei99.com/static/txauth.html";
          break;
        case "yszc":
          link = "https://test-befun-h5.2019bf.top/arearth/privacy_policy.html";
          break;
        case "vipxy":
          link = "https://test-befun-h5.2019bf.top/arearth/membership.html";
          break;
        case "sytk":
          link = "https://jiuzhou.yuebei99.com/static/useterms.html";
          break;
        case "kf":
          link =
              "https://befun-static.oss-cn-shenzhen.aliyuncs.com/arearth/ArRealityNavi_v1.0.9_h5_release_0831_109_jiagu_sign.apk";
      }
      window.location.href = link;
    },
    // 客服
    kefu() {
      Toast("在线客服仅服务VIP会员哦");
      this.$uweb.trackEvent("clcick_online_service_1", "版1点击客服", [
        `${this.$route.path}`,
      ]);
    },
    // 订单查询
    async onBeforeClose() {
      let params = {
        order_sn: window.localStorage.getItem("order_id"),
        // order_sn: "80002023083116400047120051896178",
      };
      let res = await getOrder(params);
      if (res.code == 200) {
        if (res.data.trade_state == "SUCCESS") {
          this.paySuccess = true;
          window.localStorage.setItem("paied", true);
          this.$copy("166d78d04c74c3c22e33d74eaa8c0cc7");
          this.$uweb.trackEvent(
              "hui_yaun_zhi_fu_success",
              "版1弹出开通成功弹窗",
              [`${this.$route.path}`]
          );
          setTimeout(function () {
          }, 200);
        } else {
          Toast(res.data.trade_state_desc);
          window.localStorage.setItem("order_id", "")
        }
      } else if (res.code == 400) {
        Toast("订单支付失败");
        window.localStorage.setItem("order_id", "")
        // this.$copy("166d78d04c74c3c22e33d74eaa8c0cc7");
      } else {
        window.localStorage.setItem("order_id", "")
        setTimeout(function () {
        }, 1000);
      }
    },
    // 下载app
    downLoad() {
      const that = this;
      this.download_tag = true;
      console.log(this.$route.path)
      this.$uweb.trackEvent("click_the_download_now_1", "版1点击立即下载", [
        `${this.$route.path}`,
      ]);
      setTimeout(function () {
        that.download_tag = false;
      }, 5000);
      Toast("正在下载安装包");
      window.location.href = `https://befun-static.oss-cn-shenzhen.aliyuncs.com/arearth/ArRealityNavi_v1.0.9_h5_release_0831_109_jiagu_sign.apk`;
      // this.$copy("166d78d04c74c3c22e33d74eaa8c0cc7");
      // this.$downLoad("shop");
    },
    // toWxDemo() {
    //   window.location.href = "weixin://dl/business/?t=G1gcHMUykfm";
    // },
    // 关闭action
    onCancel(str) {
      if (str === "phone") {
        this.$uweb.trackEvent("click_payment_close", "版1点击关闭", [
          `${this.$route.path}`,
        ]);
      }
      this.actionTag = false;
      this.payTag = false;
    },
    // 复制功能
    copy() {
      this.$uweb.trackEvent("click_download_copy", "版1点击复制", [
        `${this.$route.path}`,
      ]);
      this.$copy(this.copylink);
      Toast("复制成功");
    },
    getBlack() {
      this.$router.push("/");
      // window.location.href = "https://test-befun-publicize.2019bf.top/arearth"
    },
  },
};
</script>
<style lang="less" scoped>
.kyushu_1 {
  background-image: url("../assets/images/arearth/home_pay_bg.png");
  background-size: 100%;
  position: fixed;
  height: 100%;
  width: 100%;
  color: #fff;


  .back {
    width: 200px;
    object-fit: contain;
    position: absolute;
    top: 3%;
    z-index: 10;

    .back-item {
      width: 13px;
      height: 24px;
    }

    .back-text {
      width: 200px;
      height: 25px;
      font-size: 18px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
      margin-bottom: 20px;
      position: absolute;
      top: -2%;
      right: -10%;
    }

  }


  .h-title {
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      margin-right: 10px;
    }

    .text {
      width: 50%;
      text-align: left;

      .icon-t {
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 10px;
      }

      .icon-b {
        width: 216px;
      }
    }
  }

  .head {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-right: 30px;
    align-items: center;
    position: relative;

    .head-l {
      position: relative;

      .search {
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px 0px #5829f1, 0px 2px 4px 0px #5829f1 inset;
        border-radius: 13px;
        overflow: hidden;
        padding: 20px 5px;
        display: flex;
        align-items: center;

        /deep/ .van-cell {
          padding: 4px;
          font-size: 16px;
        }

        /deep/ .van-cell::after {
          display: none;
        }

        /deep/ .van-field__control {
          color: #fff;
        }

        /deep/ .van-field {
          background: transparent;
        }

        .show-btn {
          text-align: right;
          flex-shrink: 0;
          border-radius: 20px;
          padding: 17px 15px;
          background: linear-gradient(180deg, #fa9968 0%, #f6d14a 100%);
          border: unset;
          font-size: 16px;
        }
      }

      .head-r {
        width: 20%;
        position: absolute;
        top: -21%;
        right: -14%;
      }
    }
  }

  .swipe-box {
    background-size: 100%;
    width: 93%;
    height: calc(93% - 257px);
    position: absolute;
    top: 2.5%;
    left: 3.5%;
    z-index: -1;

    .my-swipe {
      color: #fff;
      font-size: 20px;
      text-align: center;
      border-radius: 12%;
      -webkit-transform: rotate(0deg);
      overflow: hidden;
      height: 100%;

      .img-item {
        // height: 50%;
        display: block;
        width: auto;
        margin: 0 auto;
      }
    }

    .swipe-bottom {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background: rgba(9, 9, 9, 0.6);
      border-radius: 0px 0px 15px 15px;
      position: absolute;
      bottom: 17%;
      left: 1px;
      right: 1px;
      top: 68%;
      line-height: 15%;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/ .van-swipe-item {
      border-radius: 12%;
      -webkit-transform: rotate(0deg);
      overflow: hidden;
    }
  }

  .pay-content {
    background-image: url("../assets/images/arearth/pay_bottom.png");
    background-size: 100%;
    width: 100%;
    // height: 35%;
    position: absolute;
    bottom: 0;
    left: 0;

    .pay_type {
      display: flex;
      justify-content: center;
      // position: absolute;
      // top: 45%;
      // left: 0%;
      width: 100%;

      .pay_center {
        display: flex;
        justify-content: center;
        align-items: center;

        .pay_state {
          color: #333333;
          width: 38vw;
          height: 48px;
          background: #F1F4F7;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          font-size: 14px;

          &:last-child {
            margin-left: 10px;
          }

          .state_title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .radio {
            margin: 0;
          }
        }
      }

    }

    .desc {
      width: 180px;
      font-size: 18px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
      margin: 15px auto;
      // position: relative;
      // top: 7%;
      // left: 50%;
      // transform: translateX(-50%);

      .free {
        color: red;
      }

      .scribe {
        width: 46px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #BCBCBC;
        line-height: 17px;
        text-decoration: line-through;
      }
    }

    .svg-box {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }

    .video-box {
      position: relative;

      //img {
      //  display: block;
      //}
    }

    /deep/ .van-cell__title {
      text-align: left;
    }

    .price-list {
      margin: 20px 0 24px;
      display: flex;
      justify-content: space-around;

      .price-item {
        padding: 10px 0 0;
        background: radial-gradient(#ffffff 60%, #dfdcff 150%);
        border-radius: 16px;
        border: 2px solid #bcc7e4;
        color: #828e96;
        overflow: hidden;
        position: relative;

        .item-title {
          font-size: 18px;
          font-family: PingFangSC;
          padding: 0 12px;
        }

        .item-price {
          padding: 10px 12px 26px;
          font-size: 26px;
        }

        .item-everyday {
          font-size: 14px;
          color: #fff;
          background: #bcc7e4;
          position: absolute;
          bottom: -2px;
          left: -2px;
          right: -2px;
        }
      }

      .active {
        border: 2px solid #9289f4;
        color: #9289f4;

        .item-everyday {
          background: #9289f4;
        }
      }
    }

    .ali-or-we {


      /deep/ .wechat {
        width: 20px;
        height: 20px;
      }

      /deep/ .van-cell {
        margin: 0 10px 12px;
        border-radius: 500px;
        background: #f3f3f3;
        width: inherit;
      }

      /deep/ .van-cell-group::after {
        border-width: 0;
      }

      .select {
        border: 2px solid #9289f4;
      }
    }

    .pay-btn {
      background-image: url("../assets/images/arearth/button.png");
      background-size: 100%;
      width: 203px;
      height: 62px;
      margin: 20px auto 10px;
      // position: absolute;
      // bottom: 12%;
      // left: 50%;
      // transform: translateX(-50%);
    }

    .clause {
      // position: absolute;
      // bottom: 3%;
      // left: 50%;
      // transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-bottom: 10px;

      .clause-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .clause-text {
        font-size: 12px;

        .clause-link {
          color: #9289f4;
        }
      }
    }
  }

  .stay-dialog {
    /deep/ .van-dialog__header {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f2c2c;
    }

    /deep/ .van-dialog__content {
      .img-box {
        width: 47px;
        height: 47px;
        margin: 15px auto;
      }

      .text {
        width: 60%;
        margin: 0 auto;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2f2c2c;
      }
    }

    /deep/ .van-hairline--top::after {
      border-top-width: 0;
    }

    /deep/ .van-dialog__footer {
      .van-button {
        width: 60%;
        background: #9289f4;
        border-radius: 26px;
        flex: unset;
        margin: 10px auto 18px;
        color: #ffffff;
      }
    }
  }

  .pay-success {
    background-image: url("../assets/images/arearth/congratulations.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 55%;
    width: 80%;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;

    .load-text {
      width: 257px;
      height: 31%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      text-align: center;
      position: absolute;
      margin-top: 44%;
      margin-left: 6%;

      //
      .load-text-value-one {
        width: 257px;
        height: 67px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }

      .load-text-value-two {
        width: 257px;
        height: 45px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        position: absolute;
        left: 0%;
        top: 53%;

        .copy {
          color: #057DFF;
        }
      }
    }

    .p-s-title {
      font-size: 24px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      margin-bottom: 10px;
      text-align: center;
    }

    .p-s-content {
      .p-s-cicon {
        width: 93px;
        height: 83px;
        margin: 0 auto 10px;
      }

      .p-s-ctext {
        text-align: left;
        font-size: 15px;
        font-family: PingFangSC;
        color: #302e51;
        margin-bottom: 10px;
      }

      .p-s-clist {
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        max-width: 220px;

        .p-s-citem {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #302e51;
          margin-bottom: 10px;

          .svg-box {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
          }

          .p-s-label {
            text-align: center;
            display: inline-block;
            width: 69px;
            flex-shrink: 0;
            font-size: 14px;
            word-break: keep-all;
          }

          .p-s-content {
            font-weight: 400;
            color: #939393;
            word-break: keep-all;
          }
        }
      }

      .p-s-clink {
        display: flex;
        width: 90%;
        margin: 5px auto 8px;
        color: #9289f4;
        justify-content: space-around;
        align-items: center;

        .link-span {
          text-align: left;
          margin-right: 10px;
          word-break: break-all;
        }

        .copy-btn {
          background: linear-gradient(180deg, #5521f0 0%, #916ff7 100%);
          border: unset;
          padding: 2px 10px;
          flex-shrink: 0;
          text-align: center;
        }
      }
    }

    .p-s-btn {
      width: 90%;
      margin: 0 auto;

      .download-btn {
        background: linear-gradient(180deg, #ffac81 0%, #ffd84b 100%);
        border: unset;
        position: absolute;
        left: 0%;
        margin-top: 100%;
      }

      .loading-btn {
        background: #bdbdbd;
        border: unset;
        position: absolute;
        left: 0%;
        margin-top: 100%;
      }
    }

    .service {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #9289f4;
      font-size: 12px;
      margin-top: 20px;

      .service-box {
        background: #f3f3f3;
        border-radius: 999px;
        // width: 50%;
        display: flex;
        align-items: center;
        padding: 4px 13px;
      }

      .svg-box {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
}

/deep/ .van-action-sheet__close {
  z-index: 99;
}

.wrapper {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;

  div {
    align-self: flex-end;
    position: relative;
    top: 0.5em;
  }

  .share_icon {
    width: 5em;
    height: 5em;
  }
}

.van-overlay {
  z-index: 8;
  text-align: right;
  font-size: 14px;

  .guide-box {
    color: white !important;
    margin-right: 10px;

    .img1 {
      width: 112px;
      height: 148px;
    }

    .img2 {
      width: 20px;
      height: 20px;
    }
  }
}
</style>