<template>
  <div class="header">
    <div class="text">
      开发者：{{ developer }}&nbsp;&nbsp;
    </div>
    <div class="text">
      开发应用名称：{{ projectName }}
    </div>
    <div class="text">
      版本号：{{ version }}&nbsp;&nbsp;
    </div>
    <div class="text">
      <span class="link" @click="linkTo('qxxq',project)">权限情况/</span>&nbsp;
      <span class="link" @click="linkTo('yszc',project)">隐私政策</span>
      <!--      <span class="link" @click="clear()">///清除会员</span>-->
    </div>
  </div>
</template>
<script>
export default {
  name: "all-header",
  props: {
    version: {
      type: String,
    },
    projectName: {
      type: String,
    },
    developer: {
      type: String,
    },
    project: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    linkTo(str, project) {
      console.log("project", project)
      let link = "";
      switch (str) {
        case "qxxq":
          link = "https://h5-cn.xxzz123.top/" + project + "/authority.html";
          break;
        case "yszc":
          link = "https://h5-cn.xxzz123.top/" + project + "/privacy_policy.html";
          break;
      }
      window.location.href = link;
    },
    clear() {
      window.localStorage.setItem("paied", '');
      window.localStorage.setItem("order_id", '');
    }
  },
};
</script>
<style lang="less" scoped>
.header {
  padding-top: 20px;
  float: right;
  z-index: 10;

  .text {
    width: 200px;
    height: 24px;
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #CBCBCB;
    line-height: 12px;

    .link {
      color: #0072FF;
    }
  }
}
</style>