import axios from 'axios'
import { Toast } from 'vant'
// const baseURL = 'https://api-cn.xxzz123.top/'
const baseURL = 'https://test-api.xxzz123.top/'
// const envStr = window.location.href.indexOf('https://street-h5-wap.hzfangqin.com/') > -1 || window.location.href.indexOf('https://jztest.2019bf.top/') > -1 ? 'NODE_PRO' : 'NODE_DEV';
// axios.defaults.baseURL = envStr == 'NODE_DEV' ? '' : baseURL;
axios.defaults.baseURL =  baseURL;
axios.interceptors.request.use(function (res) {
    // console.log(res)
    res.headers['XX-Device-Type'] = 'h5';
    res.headers['XX-Api-Version'] = '1.0.0';
    res.headers['XX-Api-Channel'] = 'h5web';
    res.headers['XX-Project'] = 'arearth';
    res.headers['XX-Lang'] = 'cn';

    return res
}, function (fail) {
    console.log(fail)
})
/**
 * 添加ajax response interceptor
 */
axios.interceptors.response.use(function (res) {
    let data = res.data
    let code = data.code
    if (data && typeof data === 'string') {
        data = JSON.parse(data)
    }

    // 过滤请求
    if (code === 0 || code === '0' || isNaN(code)) {
        return data
    } else if (code === 200 || code === 400) {
        return data
    } else {
        Toast(data.msg, 'error')// 请求异常处理
    }

}, function (error) {
    // console.log(error)
    let response = error.response
    let data = response.data || ""
    if (!response) {
        Toast('无网络链接，请检查后重试', 'error')
    } else {
        Toast(data && data.message ? data.message : '系统异常', 'error')
    }
    return data
})

/**
 * 发送 get 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function get(url, data = {}, str) {
    // res.headers['XX-Api-Channel'] = 'nine1';
    return axios.get(url, {
        params: data,
        headers: { 'Content-Type': `text/plain;`, "XX-Api-Channel": str },
        // withCredentials: true
    })
}

/**
 * 发送 post 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function post(url, data = {}, str) {
    return axios({
        url,
        method: 'post',
        headers: { 'Content-Type': `text/plain;`, "XX-Api-Channel": str },
        data,
        params: data,
        withCredentials: false
    })
}

// export function postWithJson(url, data = {}) {
//     return axios({
//         url: url,
//         method: 'post',
//         headers: { 'Content-Type': `text/plain;` },
//         data,
//         params: data,
//         withCredentials: true
//     })
// }
// /**
//  * 发送 post 请求
//  *
//  * @param {string} url 请求 url
//  * @param {Object} data 发送的数据
//  * @return {Promise}
//  */
// export function postByBody(url, data = {}) {
//     return axios({
//         url,
//         method: 'post',
//         headers: { 'Content-Type': `application/json`, "Authorization": window.localStorage.getItem('token') },
//         data,
//         withCredentials: true
//     })
// }

// /**
//  * 发送 get 请求
//  *
//  * @param {string} url 请求 url
//  * @param {Object} data 发送的数据
//  * @return {Promise}
//  */
// export function postWithGet(url, data = {}) {
//     return axios.get(url, {
//         params: data,
//         headers: { 'Content-Type': `text/plain;` },
//         // withCredentials: true
//     })
// }
// /**
//  * 发送 get 请求
//  *
//  * @param {string} url 请求 url
//  * @param {Object} data 发送的数据
//  * @return {Promise}
//  */
// export function get(url, data = {}) {
//     return axios.get(url, {
//         params: data,
//         headers: { 'Content-Type': `text/plain;`, "Authorization": window.localStorage.getItem('token') },
//         // withCredentials: true
//     })
// }
// /**
//  * 发送 post query 请求
//  *
//  * @param {string} url 请求 url
//  * @param {Object} data 发送的数据
//  * @return {Promise}
//  */
// export function postWithQuery(url, data = {}) {
//     return axios({
//         url: url,
//         method: 'post',
//         headers: { 'Content-Type': `application/json;`, "Authorization": window.localStorage.getItem('token') },
//         data,
//         params: data,
//         // withCredentials: true
//     })
// }

// /**
//  * 发送 form 请求
//  *
//  * @param {string} url 请求 url
//  * @param {Object} data 发送的数据
//  * @return {Promise}
//  */
// export function form(url, data = {}) {
//     let form = new FormData();
//     for (let key in data) {
//         form.append(key, data[key])
//     }
//     return axios
//         .post(url, form, {
//             headers: { 'Content-Type': `multipart/form-data;`, "Authorization": window.localStorage.getItem('token') },
//         })
// }
