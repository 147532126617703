import { Toast } from 'vant'
// import config from './config'
export default {
  install(Vue) {
    Vue.prototype.$testPhone = function (value) {
      if (/^1[3456789]\d{9}$/.test(value)) {
        return true
      } else {
        return false
      }
    }
    Vue.prototype.$isObj = function (arr) {
      if (JSON.stringify(arr) == "{}") {
        return true
      } else {
        return false
      }
    }/**
      * value 时间
      * mask 格式
      * isnull true可返回空 false返回当前时间
      */
    Vue.prototype.$formatDate = function (value, mask = 'YYYY-MM-DD', isnull = true) {
      let time = ''
      if (value == '') {
        time = new Date()
      } else if (typeof value == 'string' && value.indexOf('-') > -1) {
        time = new Date(value.replace(/-/g, "/"))
      } else {
        time = new Date(value)
      }
      let YYYY = time.getFullYear();
      let MM = time.getMonth() + 1
      if (MM < 10) {
        MM = '0' + MM
      }
      let DD = time.getDate()
      if (DD < 10) {
        DD = '0' + DD
      }
      let HH = time.getHours()
      if (HH < 10) {
        HH = '0' + HH
      }
      let mm = time.getMinutes()
      if (mm < 10) {
        mm = '0' + mm
      }
      let ss = time.getSeconds()
      if (ss < 10) {
        ss = '0' + ss
      }
      let rtTime = ''
      switch (mask) {
        case 'YYYY-MM': rtTime = `${YYYY}-${MM}`; break;
        case 'YYYY-MM-DD': rtTime = `${YYYY}-${MM}-${DD}`; break;
        case 'MM-DD': rtTime = `${MM}-${DD}`; break;
        case 'YYYY-MM-DD HH:mm': rtTime = `${YYYY}-${MM}-${DD} ${HH}:${mm}`; break;
        case 'MM-DD HH:mm': rtTime = `${MM}-${DD} ${HH}:${mm}`; break;
        case 'YYYY-MM-DD HH:mm:ss': rtTime = `${YYYY}-${MM}-${DD} ${HH}:${mm}:${ss}`; break;
        case 'YYYY-06-DD HH:mm:ss': rtTime = `${YYYY}-06-${DD} ${HH}:${mm}:${ss}`; break;
        case 'HH:mm': rtTime = `${HH}:${mm}`; break;
        case 'mm:ss': rtTime = `${mm}:${ss}`; break;
        case 'YYYY': rtTime = `${YYYY}`; break;
      }
      if (value == '' && isnull) {
        return ''
      } else {
        return rtTime
      }
    }
    Vue.prototype.$browserType = function () {
      let browser = {
        versions: function () {
          var u = navigator.userAgent
          // app = navigator.appVersion;
          return {         //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }
      if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
        var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          //在微信中打开
          return 'wechart'
        } else if (browser.versions.ios) {
          return 'ios'
        } else if (browser.versions.iPad) {
          return 'iPad'
        } else if (browser.versions.android) {
          return 'android'
        } else {
          return 'other'
        }
      } else {
        //否则就是PC浏览器打开
        return 'PC'
      }
    }
    /**
         * func 方法
         * wait 时间
         * 
         */
    Vue.prototype.$debounce = function (func, wait = 1000) { //可以放入项目中的公共方法中进行调用
      let timeout;
      return function (event) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          func.call(this, event)
        }, wait)
      }
    }
    /**
     * fn 方法
     * delay 
     * atleast
     */
    Vue.prototype.$fnThrottle = function (fn, delay, atleast) {  //节流函数
      let timer = null;
      let previous = null;
      return function () {
        let now = +new Date()
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          previous = now;
          clearTimeout(timer)
        } else {
          clearTimeout(timer)
          timer = setTimeout(() => {
            fn();
            previous = null
          }, delay)
        }
      }
    }
    /**
     * 复制 方法
     * str
     */
    Vue.prototype.$copy = function (str) {  //复制函数
      if ((navigator.userAgent.match(/(iPhone|iPod|iPad);?/i))) {//ios系统
        window.getSelection().removeAllRanges();//这段代码必须放在前面否则无效
        let inputDom = document.createElement('input');
        let copyDom = document.getElementsByTagName("body")[0];//要复制文字的节点
        copyDom.appendChild(inputDom);//把标签添加给body
        inputDom.style.opacity = 0;//设置input标签设置为透明(不可见)
        inputDom.value = str;//修改文本框的内容
        let range = document.createRange();
        //选中需要复制的节点
        range.selectNode(inputDom);
        //执行选中元素
        window.getSelection().addRange(range);
        inputDom.select();
        inputDom.setSelectionRange(0, inputDom.value.length);//适配高版本ios
        //执行copy操作
        let successful = document.execCommand('copy');
        let messageText = successful ? '请将此链接复制至手机或电脑浏览器打开并完成下载。' : '链接复制失败！'
        console.log(messageText)
        //移除选中的元素
        window.getSelection().removeAllRanges();
        copyDom.removeChild(inputDom)
      } else {
        //其他系统
        let inputDom = document.createElement('input');
        let copyDom = document.getElementsByTagName("body")[0]
        copyDom.appendChild(inputDom);//把标签添加给body 
        inputDom.style.opacity = 0;//设置input标签设置为透明(不可见)
        inputDom.value = str;//修改文本框的内容
        inputDom.select();//选中文本
        //执行选中元素
        let successful = document.execCommand("copy");//执行浏览器复制命令console.log
        let messageText = successful ? '请将此链接复制至手机或电脑浏览器打开并完成下载。' : '链接复制失败！'
        console.log(messageText)
        copyDom.removeChild(inputDom)
      }
    }
    /**
         * lng 经度
         * lat 纬度
         * wgs84转bd09
         */
    // var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
    var x_PI = 3.1415926535897932384626;
    var PI = 3.1415926535897932384626;
    var a = 6378245.0;
    var ee = 0.00669342162296594323;
    var transformlat = function (lng, lat) {
      let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
      ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
      return ret;
    }

    var transformlng = function (lng, lat) {
      let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
      ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
      return ret;
    }
    Vue.prototype.$wgs84ToBd09 = function (lng, lat) {
      //第一次转换 
      let dlat = transformlat(lng - 105.0, lat - 35.0);
      let dlng = transformlng(lng - 105.0, lat - 35.0);
      let radlat = lat / 180.0 * PI;
      let magic = Math.sin(radlat);
      magic = 1 - ee * magic * magic;
      let sqrtmagic = Math.sqrt(magic);
      dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
      dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
      let mglat = lat + dlat;
      let mglng = lng + dlng;

      //第二次转换 
      let z = Math.sqrt(mglng * mglng + mglat * mglat) + 0.00002 * Math.sin(mglat * x_PI);
      let theta = Math.atan2(mglat, mglng) + 0.000003 * Math.cos(mglng * x_PI);
      let bd_lng = z * Math.cos(theta) + 0.0065;
      let bd_lat = z * Math.sin(theta) + 0.006;
      return { _lng: bd_lng, _lat: bd_lat }
    }
    Vue.prototype.$bd09togcj02 = function (bd_lon, bd_lat) {
      let x = bd_lon - 0.0065, y = bd_lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * PI);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * PI);
      let gg_lng = z * Math.cos(theta);
      let gg_lat = z * Math.sin(theta);
      return { _lng: gg_lng, _lat: gg_lat }
    }
    Vue.prototype.$gcj02towgs84 = function (lng, lat) {
      let dlat = transformlat(lng - 105.0, lat - 35.0);
      let dlng = transformlng(lng - 105.0, lat - 35.0);
      let radlat = lat / 180.0 * PI;
      let magic = Math.sin(radlat);
      magic = 1 - ee * magic * magic;
      let sqrtmagic = Math.sqrt(magic);
      dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
      dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
      let mglat = lat - dlat;
      let mglng = lng - dlng;
      return { _lng: mglng, _lat: mglat }
    };
    // 跳转应用商店
    Vue.prototype.$openWithApp = function (str) {
      // var ua = navigator.userAgent.toLowerCase();
      //   window.location.href = `http://a.app.qq.com/o/simple.jsp?pkgname=${str}`;
      // } else {
      window.location.href = `market://details?id=${str}`;
      setTimeout(function () {
        window.location.href = `http://a.app.qq.com/o/simple.jsp?pkgname=${str}`;
      }, 2000)
      // }
    }
    Vue.prototype.$downLoad = function (path = 'common') {
      let brow = this.$browserType();
      console.log(brow)
      if (brow == "ios") {
        console.log("ios")
        Toast({
          message: "正在前往应用商店",
          duration: 5000
        });
        window.location.href = "https://apps.apple.com/cn/app/id6446273269";
      } else if (brow == "android") {
        if (path == 'shop') {
          console.log("shop")
          Toast({
            message: "正在前往应用商店",
            duration: 5000
          });
          window.location.href = `vivomarket://details?id=com.zphappy.armaprealitys`;//vivo
          window.location.href = `mstore://details?package_name=com.zphappy.armaprealitys`;//魅族保证最后
          window.location.href = `mimarket://details?id=com.zphappy.armaprealitys`;//小米
          window.location.href = `appmarket://details?id=com.zphappy.armaprealitys`;//华为
          window.location.href = `samsungapps://ProductDetail/com.zphappy.armaprealitys`;//三星
          window.location.href = `oppomarket://details?id=com.zphappy.armaprealitys`;//oppo
          setTimeout(function () {
            window.location.href = `https://apps.bytesfield.com/download/basic/cur/e7e118acafa67bda5d10e6de9388fe778f185bce`;
            setTimeout(function () {
              window.location.href = `http://a.app.qq.com/o/simple.jsp?pkgname=com.befun.lookworld`;//应用宝
            }, 3000)
          }, 3000)
        } else {
          let link = ''
          switch (path) {
            case 'common': link = `https://befun.2019bf.top/apk/nineWorld-common-com.befun.lookworld-9-v1.0.7-2021-11-16-17-33_107_jiagu_sign.apk`; break;
            case 'webform': link = `https://befun.2019bf.top/apk/nineWorld-webform-com.befun.lookworld-9-v1.0.7-2021-11-16-17-33_107_jiagu_sign.apk`; break;
            case 'webform1': link = `https://befun.2019bf.top/apk/nineWorld-webform1-com.befun.lookworld-11-v1.0.8-2022-01-06-17-13_108_jiagu_sign.apk`; break;
            case 'webform2': link = `https://befun.2019bf.top/apk/nineWorld-webform2-com.befun.lookworld-21-v1.1.1-2022-01-25-17-13_111_jiagu_sign.apk`; break;
            case 'webform3': link = `https://befun.2019bf.top/apk/nineWorld-webform3-com.befun.lookworld-21-v1.1.1-2022-01-25-17-13_111_jiagu_sign.apk`; break;
            case 'toutiao': link = `https://apps.bytesfield.com/download/basic/cur/e7e118acafa67bda5d10e6de9388fe778f185bce`; break;
          }
          console.log(path)
          window.location.href = link
        }

      } else {
        Toast("正在下载安装包");
        window.location.href = `https://apps.bytesfield.com/download/basic/cur/e7e118acafa67bda5d10e6de9388fe778f185bce`;
      }
    }

  }
}